/* eslint-disable space-before-function-paren */
import React, { useContext, useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import 'moment/locale/es';
import zoomPlugin from 'chartjs-plugin-zoom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
  Title,
  Tooltip,
  // ChartDataLabels,
  Filler
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Context as Auth } from '../../../services/auth/AuthContext';
import {
  createComposition,
  getCompositionByQuery,
  patchComposition
} from '../../../services/composition.service';
import { getIdToken } from 'firebase/auth';
import Spinner from '../../../components/navigation/Spinner';
import {
  Table,
  Drawer,
  Button,
  Col,
  Row,
  Input,
  Form,
  Space,
  DatePicker,
  message,
  Popconfirm
} from 'antd';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import { useParams } from 'react-router-dom';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
moment.locale('es');

const UserCorporalCompositionLog = (props) => {
  const { user } = useContext(Auth);
  const [compositions, setCompositions] = useState([]);
  const [selectedComposition, setSelectedComposition] = useState(null);
  const [compositionsReverse, setCompositionsReverse] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const params = useParams();
  const [form] = Form.useForm();

  const showDrawer = () => {
    setDrawerOpen(true);
  };
  const onClose = () => {
    form.resetFields();
    setSelectedComposition(null);
    setDrawerOpen(false);
  };

  useEffect(() => {
    if (user.account) {
      loadCompositions();
    }
  }, [user.account]);

  const loadCompositions = async () => {
    setSelectedComposition(null);
    const { status, data } = await getCompositionByQuery(
      {
        account: params.account ? params.account : user.account._id,
        isActive: true
      },
      user.token
    );
    if (status === 200) {
      setCompositions(
        data.data.sort((a, b) => new Date(a.created) - new Date(b.created))
      );
      const reverse = [...data.data];
      const chart = [...data.data.slice(Math.max(data.data.length - 30, 0))];
      reverse.reverse();
      setCompositionsReverse(reverse);
      setChartData(chart);
      setLoading(false);
    }
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Title,
    Tooltip,
    Filler,
    zoomPlugin,
    ChartDataLabels
  );

  if (loading) {
    return <Spinner />;
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        position: 'left',
        title: {
          display: true,
          text: 'Kg.'
        },
        ticks: {
          stepSize: 10
        }
        // suggestedMin: 20,
        // suggestedMax: 120
      }
    },
    y2: {
      position: 'right',
      title: {
        display: true,
        text: capitalize(t('percent'))
      },
      ticks: {
        stepSize: 10
      },
      suggestedMin: 0,
      suggestedMax: 100
    },
    plugins: {
      legend: {
        position: 'top'
      },
      datalabels: {
        font: {
          size: '14px',
          weight: 'bold'
        },
        align: 'top',
        anchor: 'top',
        formatter: function (value, context) {
          if (
            context.dataset.label === capitalize(t('weight')) ||
            context.dataset.label === capitalize(t('muscle'))
          ) {
            return value + ' Kg.';
          } else {
            return value + ' %';
          }
        },
        color: function (context) {
          if (context.dataset.label === capitalize(t('weight'))) {
            return 'rgb(0, 144, 189)';
          } else if (context.dataset.label === capitalize(t('muscle'))) {
            return 'rgb(35, 125, 0)';
          } else {
            return 'rgb(120, 104, 0)';
          }
        }
      }
    }
  };

  const labels = chartData.map((composition) =>
    moment(composition.created).format('L')
  );

  const data = {
    labels,
    datasets: [
      {
        id: 1,
        label: capitalize(t('weight')),
        borderColor: 'rgb(79,215,255)',
        // backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: false,
        yAxisID: 'y',
        data: chartData.map((composition) => composition.weight)
      },
      {
        id: 1,
        label: capitalize(t('muscle')),
        borderColor: 'rgb(82,185,41)',
        // backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: false,
        yAxisID: 'y',
        data: chartData.map((composition) => composition.muscle)
      },
      {
        id: 1,
        label: capitalize(t('fat')),
        borderColor: 'rgb(185,166,41)',
        // backgroundColor: 'rgba(193, 221, 230,0.5)',
        fill: false,
        yAxisID: 'y2',
        data: chartData.map((composition) => composition.fat)
      }
    ]
  };

  const columns = [
    {
      title: capitalize(t('corporalComposition')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          <b>{moment(record.created).format('DD/MM/YYYY HH:mm')}</b>
          <br />
          <span style={{ fontWeight: 500 }}>{t('weight')}</span>:{' '}
          {record.weight}Kg <br />
          <span style={{ fontWeight: 500 }}>{t('muscle')}</span>:{' '}
          {record.muscle}Kg <br />
          <span style={{ fontWeight: 500 }}>{t('fat')}</span>: {record.fat}%{' '}
          <br />
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.created.localeCompare(b.created),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      dataIndex: ['created'],
      key: 'date',
      align: 'center',
      render: (text, record) =>
        moment(record.created).format('DD/MM/YYYY HH:mm'),
      sorter: {
        compare: (a, b) => a.created.localeCompare(b.created),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('weight')),
      align: 'center',
      // dataIndex: ['name'],
      key: 'weight',
      // render: (text, record) => record.name,
      render: (text, record) => record.weight + 'Kg',
      responsive: ['sm']
    },
    {
      title: capitalize(t('muscle')),
      align: 'center',
      // dataIndex: ['name'],
      key: 'muscle',
      // render: (text, record) => record.name,
      render: (text, record) =>
        record.muscle ? record.muscle + 'Kg' : capitalize(t('noData')),
      responsive: ['sm']
    },
    {
      title: capitalize(t('fat')),
      align: 'center',
      // dataIndex: ['name'],
      key: 'fat',
      // render: (text, record) => record.name,
      render: (text, record) =>
        record.fat ? record.fat + '%' : capitalize(t('noData')),
      responsive: ['sm']
    }
  ];

  const fillForm = (composition) => {
    form.setFieldsValue({
      weight: composition.weight,
      muscle: composition.muscle,
      fat: composition.fat,
      date: moment(composition.created)
    });
  };

  const onFormSubmit = async () => {
    const payload = {
      weight: form.getFieldValue('weight'),
      muscle: form.getFieldValue('muscle'),
      fat: form.getFieldValue('fat'),
      created: form.getFieldValue('date'),
      account: params.account
    };

    if (selectedComposition) {
      const { status, data } = await patchComposition(
        selectedComposition._id,
        payload,
        user.token
      );
      if (status === 200) {
        setDrawerOpen(false);
        form.resetFields();
        setSelectedComposition(null);
        loadCompositions();
      }
      return;
    }
    const { status, data } = await createComposition(payload, user.token);
    if (status === 200) {
      setDrawerOpen(false);
      form.resetFields();
      loadCompositions();
    }
  };

  const checkForm = () => {};

  function onChange(pagination, filters, sorter, extra) {}

  return (
    <>
      {(user.account.roles.indexOf('organizer') >= 0 ||
        user.account.roles.indexOf('trainer') >= 0) && (
        <Button
          type='primary'
          onClick={showDrawer}
          shape='round'
          icon={<PlusOutlined />}
          size='large'
          style={{ marginBottom: 18 }}
        >
          {capitalizeAll(t('newRegister'))}
        </Button>
      )}

      <div style={{ height: 300 }}>
        <Line datasetIdKey='id' data={data} options={options} />
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record._id}
        dataSource={compositionsReverse}
        onChange={onChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSelectedComposition(record);
              fillForm(record);
              showDrawer();
            }
          };
        }}
      />
      <Drawer
        height={'460px'}
        title={
          selectedComposition
            ? moment(selectedComposition.created).format('DD/MM/YYYY HH:mm')
            : capitalizeAll(t('newRegister'))
        }
        placement={'bottom'}
        onClose={onClose}
        open={drawerOpen}
        bodyStyle={{}}
        extra={
          <Space>
            {selectedComposition && (
              <Popconfirm
                title={capitalize(t('confirmDeleteComposition'))}
                onConfirm={async () => {
                  const { status, data } = await patchComposition(
                    selectedComposition._id,
                    { isActive: false },
                    user.token
                  );
                  if (status === 200) {
                    message.success(capitalize(t('compositionDeleted')));
                    loadCompositions();
                    onClose();
                  }
                }}
                okText={capitalizeAll(t('yes'))}
                cancelText={capitalizeAll(t('no'))}
              >
                <Button
                  type='primary'
                  danger
                  icon={<DeleteOutlined />}
                ></Button>
              </Popconfirm>
            )}
            <Button onClick={onClose}>{capitalizeAll(t('cancel'))}</Button>
            <Button onClick={onFormSubmit} type='primary'>
              {selectedComposition
                ? capitalizeAll(t('edit'))
                : capitalizeAll(t('save'))}
            </Button>
          </Space>
        }
      >
        <Form layout='vertical' form={form} onFinish={onFormSubmit}>
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item
                name='weight'
                label={capitalize(t('weight'))}
                rules={[
                  {
                    required: true,
                    message: capitalize(t('weight') + ' ' + t('isRequired'))
                  }
                ]}
              >
                <Input
                  placeholder={capitalize(t('weight'))}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
              <Form.Item
                name='muscle'
                label={capitalize(t('muscle'))}
                rules={[
                  {
                    required: true,
                    message: capitalize(t('muscle') + ' ' + t('isRequired'))
                  }
                ]}
              >
                <Input
                  placeholder={capitalize(t('muscle'))}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
              <Form.Item
                name='fat'
                label={capitalize(t('fat'))}
                rules={[
                  {
                    required: true,
                    message: capitalize(t('fat') + ' ' + t('isRequired'))
                  }
                ]}
              >
                <Input
                  placeholder={capitalize(t('fat'))}
                  type='number'
                  onWheel={(e) => e.target.blur()}
                />
              </Form.Item>
              <Form.Item
                name='date'
                label={capitalize(t('date'))}
                rules={[
                  {
                    required: true,
                    message: capitalize(t('date') + ' ' + t('isRequired'))
                  }
                ]}
              >
                <DatePicker
                  showTime
                  style={{ width: '100%' }}
                  defaultValue={moment()}
                  format='DD/MM/YYYY HH:mm'
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default UserCorporalCompositionLog;
