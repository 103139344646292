/* eslint-disable space-before-function-paren */
import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Form, Input, Table, Upload } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  ControlOutlined,
  EditOutlined,
  EuroOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  StockOutlined,
  CalendarOutlined,
  RadarChartOutlined,
  SettingOutlined,
  UploadOutlined,
  TrophyOutlined,
  MailOutlined,
  DashboardOutlined,
  LineChartOutlined,
  AreaChartOutlined,
  FundOutlined,
  BellOutlined
} from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import {
  createAccount,
  getAccountByQueryWithPrograms
} from '../../../services/user.service';
import '../../../components/layout/layout.css';
import {
  getCenterByQuery,
  getCenterById
} from '../../../services/center.service';
import {
  createUserWithEmailAndPassword,
  getAuth,
  getIdToken,
  signOut
} from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

import * as XLSX from 'xlsx';
import { signUpApp } from '../../../services/firebase/firebaseConfig';
import { getAllPrograms } from '../../../services/program.service';
import moment from 'moment';
import 'moment/locale/es';
moment.updateLocale('es', {
  longDateFormat: {
    LL: 'D [de] MMMM'
  }
});
moment.updateLocale('en', {
  longDateFormat: {
    LL: 'MMMM D'
  }
});
moment.locale('es');

const Users = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [users, setUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [birthdates, setBirthdates] = useState([]);
  const [nextBirthdates, setNextBirthdates] = useState([]);
  const [center, setCenter] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  useEffect(() => {
    if (
      users &&
      users.length > 0 &&
      filter !== '' &&
      filter !== null &&
      filter !== undefined
    ) {
      const filtered = users.filter((user) => {
        return (
          user.name?.toLowerCase().includes(filter.toLowerCase()) ||
          user.email?.toLowerCase().includes(filter.toLowerCase()) ||
          user.phone?.toLowerCase().includes(filter.toLowerCase()) ||
          user.nif?.toLowerCase().includes(filter.toLowerCase()) ||
          user.surname?.toLowerCase().includes(filter.toLowerCase())
        );
      });
      setFilteredUsers(filtered);
    } else {
      setFilteredUsers(users);
    }
  }, [filter, users]);

  const loadUsers = async (centerId) => {
    const { status, data } = await getAccountByQueryWithPrograms(
      { roles: 'user', center: centerId, isActive: true },
      user.token
    );
    if (status === 200) {
      data.data.forEach((user) => {
        user.key = user._id;
      });
      areThereBirthdaysToday(data.data);
      setUsers(data.data.reverse());
      // setFilteredUsers(data.data);
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery(
      { organizer: user.account._id },
      user.token
    );
    if (status === 200) {
      loadUsers(data.data[0]._id);
      setCenter(data.data[0]);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(
      user.account.center,
      user.token
    );
    if (status === 200) {
      loadUsers(data.data._id);
      setCenter(data.data);
    }
  };

  const areThereBirthdaysToday = (users) => {
    const today = new Date();
    const todayString = today.getDate() + '-' + (today.getMonth() + 1);
    const todayUsers = users.filter((user) => {
      const birthdate = new Date(user.birthdate);
      const birthdateString =
        birthdate.getDate() + '-' + (birthdate.getMonth() + 1);
      return todayString === birthdateString;
    });
    setBirthdates(todayUsers);
    // get next 7 days birthdays
    const day1String = today.getDate() + 1 + '-' + (today.getMonth() + 1);
    const day2String = today.getDate() + 2 + '-' + (today.getMonth() + 1);
    const day3String = today.getDate() + 3 + '-' + (today.getMonth() + 1);
    const day4String = today.getDate() + 4 + '-' + (today.getMonth() + 1);
    const day5String = today.getDate() + 5 + '-' + (today.getMonth() + 1);
    const day6String = today.getDate() + 6 + '-' + (today.getMonth() + 1);
    const day7String = today.getDate() + 7 + '-' + (today.getMonth() + 1);
    const nextBirthdates = users.filter((user) => {
      const birthdate = new Date(user.birthdate);
      const birthdateString =
        birthdate.getDate() + '-' + (birthdate.getMonth() + 1);
      return (
        day1String === birthdateString ||
        day2String === birthdateString ||
        day3String === birthdateString ||
        day4String === birthdateString ||
        day5String === birthdateString ||
        day6String === birthdateString ||
        day7String === birthdateString
      );
    });
    setNextBirthdates(
      nextBirthdates.sort((a, b) => {
        const aDate = moment(a.birthdate).format('MMDD');
        const bDate = moment(b.birthdate).format('MMDD');
        return aDate - bDate;
      })
    );
  };

  const columns = [
    {
      title: capitalize(t('user')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name} {record.surname}
          <br />
          {record.email}
          <br />
          {record.phone}
          <br />
          {record.nif}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name', 'surname'],
      key: '_id',

      render: (text, record) => record.name + ' ' + record.surname,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('email')),
      dataIndex: 'email',
      key: 'email',
      sorter: {
        compare: (a, b) => a.email.localeCompare(b.email),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('phone')),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a.phone.localeCompare(b.phone),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: t('nif').toUpperCase(),
      dataIndex: 'nif',
      key: 'nif',
      sorter: {
        compare: (a, b) => a.nif.localeCompare(b.nif),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('programs')),
      key: 'programs',
      render: (text, record) => {
        return record.programs;
      },
      sorter: {
        compare: (a, b) => {
          return a.programs - b.programs;
        }
      },
      responsive: ['sm']
    }
  ];

  const createNewAccount = async (data, uid) => {
    const formData = {
      name: data.name,
      surname: data.surname,
      email: data.email,
      firebaseUid: uid,
      phone: data.phone,
      roles: 'user',
      nif: data.nif,
      birthdate: data.birthdate,
      credits: data.credits,
      center: center._id
    };
    await createAccount(formData, user.token);
  };

  const createFirebaseAccount = async (e) => {
    const { email, passwd } = e;
    const auth = getAuth(signUpApp);
    await createUserWithEmailAndPassword(auth, email, passwd)
      .then((result) => {
        createNewAccount(e, result.user.uid);
      })
      .catch((error) => {
        console.log(error);
      });
    await signOut(auth);
  };

  const uploadExcel = async (e) => {
    e.preventDefault();

    const files = e.target.files;
    const f = files[0];
    const reader = new FileReader();
    reader.onload = async function (e) {
      const data = e.target.result;
      const readedData = XLSX.read(data, { type: 'binary' });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      /* Convert array to json */
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const dataParseFiltered = dataParse.splice(0, 1);

      // -----------------------------------UPLOAD
      for await (const user of dataParse) {
        const payload = {
          name: user[1],
          surname: user[2] ? user[2] : '',
          email: user[3],
          phone: user[4] !== undefined ? user[4] : '611111111',
          roles: 'user',
          nif: user[8].toString(),
          passwd: user[8].toString(),
          birthdate: '01-01-2000',
          credits: 0,
          center: center._id
        };
        await new Promise((resolve) => setTimeout(resolve, 2000));
        createFirebaseAccount(payload);
      }
    };
    reader.readAsBinaryString(f);
  };

  function onChange(pagination, filters, sorter, extra) {}

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <>
        <Button
          type='primary'
          shape='round'
          icon={<PlusOutlined />}
          size='large'
          onClick={() => navigate('add')}
          style={{ marginBottom: 12 }}
        >
          {capitalize(t('createNewUser'))}
        </Button>
        {birthdates.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              marginBottom: 12
            }}
          >
            <h1 style={{ marginBottom: 0 }}>
              {capitalize(t('birthdaysToday'))}
            </h1>
            {birthdates.map((user) => {
              return (
                <div key={user._id}>
                  {'🎂​'} {user.name} {user.surname}
                </div>
              );
            })}
          </div>
        )}
        {nextBirthdates.length > 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              marginBottom: 18
            }}
          >
            <h3 style={{ marginBottom: 0 }}>
              {capitalize(t('nextBirthdays'))}
            </h3>
            {nextBirthdates.map((user) => {
              return (
                <div key={user._id}>
                  {'🎂​'} {user.name} {user.surname}{' '}
                  <span style={{ fontSize: 11, color: '#5e000d' }}>
                    ({moment(user.birthdate).format('LL')})
                  </span>
                </div>
              );
            })}
          </div>
        )}
        {/* <input type={'file'} onChange={(e) => {
          uploadExcel(e);
        }} /> */}
        <Input
          placeholder={capitalize(t('search'))}
          onChange={(e) => setFilter(e.target.value)}
          style={{ marginBottom: 18 }}
        />
        <Table
          columns={columns}
          dataSource={filteredUsers}
          onChange={onChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {}, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {} // mouse leave row
            };
          }}
          rowClassName={'row-pointer'}
          expandable={{
            // eslint-disable-next-line react/display-name
            expandedRowRender: (record) => (
              <React.Fragment>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                    gap: 10
                  }}
                >
                  {center?.plan[0] === '1' && (
                    <>
                      <Button
                        icon={<EditOutlined />}
                        onClick={() => {
                          navigate('edit/' + record._id);
                        }}
                      >
                        {capitalize(t('edit'))}
                      </Button>
                      <Button
                        icon={<FolderOpenOutlined />}
                        onClick={() => {
                          navigate('histories/' + record._id);
                        }}
                      >
                        {capitalize(t('history'))}
                      </Button>
                    </>
                  )}
                  {/* <Button icon={<EuroOutlined />} onClick={() => { navigate('credits/' + record._id); }}>{capitalize(t('addCredits'))}</Button> */}
                  {center?.plan[1] === '1' && (
                    <>
                      <Button
                        icon={<TrophyOutlined />}
                        onClick={() => {
                          navigate('strength/' + record._id);
                        }}
                      >
                        {capitalize(t('strengthTest'))}
                      </Button>
                      <Button
                        icon={<ControlOutlined />}
                        onClick={() => {
                          navigate('programs/' + record._id);
                        }}
                      >
                        {capitalize(t('programs'))}
                      </Button>
                      <Button
                        icon={<SettingOutlined />}
                        onClick={() => {
                          navigate('settings/' + record._id);
                        }}
                      >
                        {capitalize(t('settings'))}
                      </Button>
                      <Button
                        icon={<RadarChartOutlined />}
                        onClick={() => {
                          navigate('programStats/' + record._id);
                        }}
                      >
                        {capitalize(t('programStats'))}
                      </Button>
                      <Button
                        icon={<AreaChartOutlined />}
                        onClick={() => {
                          navigate('exerciseStats/' + record._id);
                        }}
                      >
                        {capitalize(t('exerciseStats'))}
                      </Button>
                      <Button
                        icon={<DashboardOutlined />}
                        onClick={() => {
                          navigate('weight/' + record._id);
                        }}
                      >
                        {capitalize(t('weightStats'))}
                      </Button>
                    </>
                  )}
                  {center?.plan[6] === '1' && (
                    <>
                      <Button
                        icon={<BellOutlined />}
                        onClick={() => {
                          navigate('notifications/' + record._id);
                        }}
                      >
                        {capitalize(t('notifications'))}
                      </Button>
                    </>
                  )}
                  {center?.plan[4] === '1' && (
                    <>
                      <Button
                        icon={<LineChartOutlined />}
                        onClick={() => {
                          navigate('composition/' + record._id);
                        }}
                      >
                        {capitalize(t('corporalCompositionStats'))}
                      </Button>
                    </>
                  )}
                  {center?.plan[5] === '1' && (
                    <>
                      <Button
                        icon={<FundOutlined />}
                        onClick={() => {
                          navigate('evaluation/' + record._id);
                        }}
                      >
                        {capitalize(t('evaluations'))}
                      </Button>
                    </>
                  )}
                  {center?.plan[3] === '1' && (
                    <>
                      <Button
                        icon={<MailOutlined />}
                        onClick={() => {
                          navigate('comunications/' + record._id);
                        }}
                      >
                        {capitalize(t('comunications'))}
                      </Button>
                    </>
                  )}
                  {center?.plan[2] === '1' && (
                    <>
                      <Button
                        icon={<CalendarOutlined />}
                        onClick={() => {
                          navigate('bookings/' + record._id);
                        }}
                      >
                        {capitalize(t('bookings'))}
                      </Button>
                    </>
                  )}
                </div>
              </React.Fragment>
            ),
            rowExpandable: (record) => record.name !== 'Not Expandable',
            expandRowByClick: true
          }}
        />
      </>
    );
  }
};

export default Users;
