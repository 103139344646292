import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MenuProvider from '@elekajota/react-flexible-sliding-menu-scroll';
import Login from '../../pages/auth/Login';
import Signup from '../../pages/auth/Signup';
import { Provider as AuthProvider } from '../../services/auth/AuthContext';
import DashboardHome from '../../pages/dashboard/home';
import NotFound from '../../pages/NotFound';
import MainLayout from '../layout/MainLayout';
import Centers from '../../pages/dashboard/centers/Centers';
import CenterSessions from '../../pages/dashboard/sessions/CenterSessions';
import CreateCenter from '../../pages/dashboard/centers/CreateCenter';
import CenterDetail from '../../pages/dashboard/centers/CenterDetail';
import Organizers from '../../pages/dashboard/organizers/Organizers';
import CreateOrganizer from '../../pages/dashboard/organizers/CreateOrganizer';
import MyCenter from '../../pages/dashboard/centers/MyCenter';
import Trainers from '../../pages/dashboard/trainers/Trainers';
import CreateTrainer from '../../pages/dashboard/trainers/CreateTrainer';
import Users from '../../pages/dashboard/users/Users';
import CreateUser from '../../pages/dashboard/users/CreateUser';
import AdminTrainers from '../../pages/dashboard/trainers/AdminTrainers';
import AdminUsers from '../../pages/dashboard/users/AdminUsers';
import Devices from '../../pages/dashboard/devices/Devices';
import CreateDevice from '../../pages/dashboard/devices/CreateDevice';
import Comunicationtemplates from '../../pages/dashboard/comunicationtemplates/Comunicationtemplate';
import CreateComunicationtemplate from '../../pages/dashboard/comunicationtemplates/CreateComunicationtemplate';
import SendComunicationtemplate from '../../pages/dashboard/sendcomunication/SendComunication';
import Exercises from '../../pages/dashboard/exercises/Exercises';
import CreateExercise from '../../pages/dashboard/exercises/CreateExercise';
import TopMenu from './TopMenu';
import Logout from '../../pages/auth/Logout';
import Programs from '../../pages/dashboard/programs/Programs';
import CreateProgram from '../../pages/dashboard/programs/CreateProgram';
import Settings from '../../pages/dashboard/settings/Settings';
import CreateSetting from '../../pages/dashboard/settings/CreateSetting';
import Strength from '../../pages/dashboard/strength/Strength';
import CreateStrength from '../../pages/dashboard/strength/CreateStrength';
// import BookingCalendar from '../../pages/dashboard/booking/BookingCalendar';
// import Exercise from '../../pages/client/device/Exercise';
import Session from '../../pages/client/session';
import ClientPrograms from '../../pages/client/session/ClientPrograms';
import Device from '../../pages/client/device';
import ClientExercises from '../../pages/client/device/ClientExercises';
import Isometrics from '../../pages/isometrics/Isometrics';
import UserHome from '../../pages/user/home';
import Redirect from '../../pages/Redirect';
import UserBooking from '../../pages/user/booking';
import UserProfile from '../../pages/user/profile';
import Histories from '../../pages/dashboard/histories/Histories';
import CreateHistory from '../../pages/dashboard/histories/CreateHistory';
import UserExercises from '../../pages/user/exercises';
import ExerciseLog from '../../pages/user/exercises/Exercise';
import UserWeightLog from '../../pages/user/weight';
import UserPrograms from '../../pages/user/programs';
import ProgramLog from '../../pages/user/programs/Program';
import BookingDashboard from '../../pages/dashboard/booking/Booking';
import Sessions from '../../pages/dashboard/sessions/Sessions';
import ExternalLayout from '../layout/ExternalLayout';
import ExternalHistories from '../../pages/external/histories/Histories';
import ExternalCreateHistory from '../../pages/external/histories/CreateHistory';
import SuccessPay from '../../pages/user/profile/successPay';
import Transactions from '../../pages/dashboard/transactions/Transactions';
import Booksession from '../../pages/dashboard/booksessions/Booksession';
import CreateBooksession from '../../pages/dashboard/booksessions/CreateBooksession';
import Credits from '../../pages/dashboard/credits/Credits';
import UserStrength from '../../pages/user/strength';
import StrengthTest from '../../pages/user/strength/StrengthTest';
import UserComunications from '../../pages/dashboard/users/Comunications';
import UserCorporalCompositionLog from '../../pages/user/composition';
import UserBookings from '../../pages/dashboard/userbookings';
import Evaluation from '../../pages/dashboard/evaluation/Evaluation';
import CreateEvaluation from '../../pages/dashboard/evaluation/CreateEvaluation';
import Notifications from '../../pages/dashboard/notifications/Notifications';
import CreateNotification from '../../pages/dashboard/notifications/CreateNotification';

const RoutesManagement = (props) => {
  const routesList = [
    {
      name: 'redirect',
      path: '/redirect',
      element: <Redirect />
    },
    {
      name: 'dashboard',
      path: '/dashboard',
      element: <DashboardHome />
    },
    {
      name: 'centers',
      path: 'dashboard/centers',
      element: <Centers />
    },
    {
      name: 'add center',
      path: '/dashboard/centers/add',
      element: <CreateCenter />
    },
    {
      name: 'edit center',
      path: '/dashboard/centers/edit/:id',
      element: <CreateCenter />
    },
    {
      name: 'center sessions',
      path: 'dashboard/centers/sessions',
      element: <CenterSessions />
    },
    {
      name: 'center detail',
      path: 'dashboard/centers/:id',
      element: <CenterDetail />
    },
    {
      name: 'organizers',
      path: 'dashboard/organizers',
      element: <Organizers />
    },
    {
      name: 'add organizer',
      path: '/dashboard/organizers/add',
      element: <CreateOrganizer />
    },
    {
      name: 'edit organizer',
      path: '/dashboard/organizers/edit/:id',
      element: <CreateOrganizer />
    },
    {
      name: 'my center',
      path: '/dashboard/my-center',
      element: <MyCenter />
    },
    {
      name: 'trainers',
      path: 'dashboard/trainers',
      element: <Trainers />
    },
    {
      name: 'add trainer',
      path: '/dashboard/trainers/add',
      element: <CreateTrainer />
    },
    {
      name: 'edit trainer',
      path: '/dashboard/trainers/edit/:id',
      element: <CreateTrainer />
    },
    {
      name: 'admin trainers',
      path: 'dashboard/admin-trainers',
      element: <AdminTrainers />
    },
    {
      name: 'users',
      path: 'dashboard/users',
      element: <Users />
    },
    {
      name: 'add user',
      path: '/dashboard/users/add',
      element: <CreateUser />
    },
    {
      name: 'edit user',
      path: '/dashboard/users/edit/:id',
      element: <CreateUser />
    },
    {
      name: 'admin users',
      path: 'dashboard/admin-users',
      element: <AdminUsers />
    },
    {
      name: 'devices',
      path: 'dashboard/devices',
      element: <Devices />
    },
    {
      name: 'add device',
      path: '/dashboard/devices/add',
      element: <CreateDevice />
    },
    {
      name: 'edit device',
      path: '/dashboard/devices/edit/:id',
      element: <CreateDevice />
    },
    {
      name: 'comunicationtemplates',
      path: 'dashboard/comunicationtemplates',
      element: <Comunicationtemplates />
    },
    {
      name: 'add comunicationtemplate',
      path: '/dashboard/comunicationtemplates/add',
      element: <CreateComunicationtemplate />
    },
    {
      name: 'edit comunicationtemplate',
      path: '/dashboard/comunicationtemplates/edit/:id',
      element: <CreateComunicationtemplate />
    },
    {
      name: 'send comunication',
      path: '/dashboard/comunication/send',
      element: <SendComunicationtemplate />
    },
    {
      name: 'exercises',
      path: 'dashboard/exercises',
      element: <Exercises />
    },
    {
      name: 'add exercise',
      path: '/dashboard/exercises/add',
      element: <CreateExercise />
    },
    {
      name: 'edit exercise',
      path: '/dashboard/exercises/edit/:id',
      element: <CreateExercise />
    },
    {
      name: 'book session',
      path: 'dashboard/booksessions',
      element: <Booksession />
    },
    {
      name: 'add book session',
      path: '/dashboard/booksessions/add',
      element: <CreateBooksession />
    },
    {
      name: 'edit book session',
      path: '/dashboard/booksessions/edit/:id',
      element: <CreateBooksession />
    },
    {
      name: 'programs',
      path: '/dashboard/users/programs/:account',
      element: <Programs />
    },
    {
      name: 'add program',
      path: '/dashboard/users/programs/:account/add',
      element: <CreateProgram />
    },
    {
      name: 'edit programs',
      path: '/dashboard/users/programs/edit/:id',
      element: <CreateProgram />
    },
    {
      name: 'settings',
      path: '/dashboard/users/settings/:account',
      element: <Settings />
    },
    {
      name: 'add settings',
      path: '/dashboard/users/settings/:account/add',
      element: <CreateSetting />
    },
    {
      name: 'edit settings',
      path: '/dashboard/users/settings/edit/:id',
      element: <CreateSetting />
    },
    {
      name: 'strength',
      path: '/dashboard/users/strength/:account',
      element: <Strength />
    },
    {
      name: 'add strength',
      path: '/dashboard/users/strength/:account/add',
      element: <CreateStrength />
    },
    {
      name: 'edit strength',
      path: '/dashboard/users/strength/edit/:id',
      element: <CreateStrength />
    },
    {
      name: 'evaluation',
      path: '/dashboard/users/evaluation/:account',
      element: <Evaluation />
    },
    {
      name: 'add evaluation',
      path: '/dashboard/users/evaluation/:account/add',
      element: <CreateEvaluation />
    },
    {
      name: 'edit evaluation',
      path: '/dashboard/users/evaluation/edit/:id',
      element: <CreateEvaluation />
    },
    {
      name: 'histories',
      path: '/dashboard/users/histories/:account',
      element: <Histories />
    },
    {
      name: 'add histories',
      path: '/dashboard/users/histories/:account/add',
      element: <CreateHistory />
    },
    {
      name: 'edit histories',
      path: '/dashboard/users/histories/edit/:id',
      element: <CreateHistory />
    },
    {
      name: 'notifications',
      path: '/dashboard/users/notifications/:account',
      element: <Notifications />
    },
    {
      name: 'add notifications',
      path: '/dashboard/users/notifications/:account/add',
      element: <CreateNotification />
    },
    {
      name: 'edit notifications',
      path: '/dashboard/users/notifications/edit/:id',
      element: <CreateNotification />
    },
    {
      name: 'user bookings',
      path: '/dashboard/users/bookings/:account',
      element: <UserBookings />
    },
    {
      name: 'user program stats',
      path: '/dashboard/users/programStats/:account',
      element: <UserPrograms />
    },
    {
      name: 'user exercise stats',
      path: '/dashboard/users/exerciseStats/:account',
      element: <UserExercises />
    },
    {
      name: 'user exercise log',
      path: '/user/exercise/:id/:account',
      element: <ExerciseLog />
    },
    {
      name: 'user corporal composition logs',
      path: '/dashboard/users/composition/:account',
      element: <UserCorporalCompositionLog />
    },
    {
      name: 'user weight logs',
      path: '/dashboard/users/weight/:account',
      element: <UserWeightLog />
    },
    {
      name: 'user comunications',
      path: '/dashboard/users/comunications/:id',
      element: <UserComunications />
    },
    {
      name: 'booking calendar',
      path: '/dashboard/booking/:selected',
      element: <BookingDashboard />
    },
    {
      name: 'booking calendar',
      path: '/dashboard/booking/:selected/:action',
      element: <BookingDashboard />
    },
    {
      name: 'booking calendar',
      path: '/dashboard/booking/:selected/:action/:id',
      element: <BookingDashboard />
    },
    {
      name: 'booking calendar',
      path: '/dashboard/booking/',
      element: <BookingDashboard />
    },
    {
      name: 'sessions',
      path: '/dashboard/sessions/',
      element: <Sessions />
    },
    {
      name: 'transactions',
      path: '/dashboard/transactions/',
      element: <Transactions />
    },
    {
      name: 'user home',
      path: '/user',
      element: <UserHome />
    },
    {
      name: 'user booking',
      path: '/user/booking',
      element: <UserBooking />
    },
    {
      name: 'user profile',
      path: '/user/profile',
      element: <UserProfile />
    },
    {
      name: 'user exercises',
      path: '/user/exercises',
      element: <UserExercises />
    },
    {
      name: 'user exercise logs',
      path: '/user/exercise/:id',
      element: <ExerciseLog />
    },
    {
      name: 'user strength tests',
      path: '/user/strength',
      element: <UserStrength />
    },
    {
      name: 'user strength logs',
      path: '/user/strength/:id',
      element: <StrengthTest />
    },
    {
      name: 'user weight logs',
      path: '/user/weight',
      element: <UserWeightLog />
    },
    {
      name: 'user corporal composition logs',
      path: '/user/composition',
      element: <UserCorporalCompositionLog />
    },
    {
      name: 'user programs',
      path: '/user/programs',
      element: <UserPrograms />
    },
    {
      name: 'user program logs',
      path: '/user/program/:id',
      element: <ProgramLog />
    },
    {
      name: 'success pay',
      path: '/user/profile/success',
      element: <SuccessPay />
    },
    {
      name: 'credits',
      path: '/dashboard/credits',
      element: <Credits />
    }
  ];

  const externalRoutes = [
    {
      name: 'histories',
      path: '/ext/histories/:nif',
      element: <ExternalHistories />
    },
    {
      name: 'add histories',
      path: '/ext/histories/:nif/:account/add',
      element: <ExternalCreateHistory />
    },
    {
      name: 'edit histories',
      path: '/ext/histories/:nif/edit/:id',
      element: <ExternalCreateHistory />
    }
  ];

  const newRoute = (path, element, key) => {
    return (
      <Route
        key={key}
        path={path}
        element={
          <MenuProvider
            MenuComponent={TopMenu}
            direction={'right'}
            className='scroll'
          >
            <MainLayout>
              <Routes>
                <Route path='/' element={element} />
              </Routes>
            </MainLayout>
          </MenuProvider>
        }
      />
    );
  };

  const externalRoute = (path, element, key) => {
    return (
      <Route
        key={key}
        path={path}
        element={
          <ExternalLayout>
            <Routes>
              <Route path='/' element={element} />
            </Routes>
          </ExternalLayout>
        }
      />
    );
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/login' element={<Login />} />
          <Route path='/logout' element={<Logout />} />
          <Route path='/signup' element={<Signup />} />
          <Route path='/device/:center/:device' element={<Device />} />
          <Route
            path='/device/:center/:device/:account'
            element={<ClientExercises />}
          />
          <Route path='/training/:center' element={<Session />} />
          <Route
            path='/training/:center/:account'
            element={<ClientPrograms />}
          />
          <Route path='/isometrics' element={<Isometrics />} />
          {routesList.map((route, key) => {
            return newRoute(route.path, route.element, key);
          })}
          {externalRoutes.map((route, key) => {
            return externalRoute(route.path, route.element, key);
          })}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default RoutesManagement;
