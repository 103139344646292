/* eslint-disable multiline-ternary */
import React, { useState } from 'react';
import { Button } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../utils/utils';
import ReactPlayer from 'react-player';

const VideoModal = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <Modal
        title={capitalizeAll(t('youtubeVideo'))}
        visible={props.videoModalVisible}
        onOk={() => {
          props.setVideoModalVisible(false);
        }}
        onCancel={() => {
          props.setVideoModalVisible(false);
        }}
        onPan={(p) => {}}
        centered
        width={'100%'}
        bodyStyle={{ height: 550 }}
        footer={[
          <Button
            key='OK'
            onClick={() => {
              props.setVideoModalVisible(false);
            }}
          >
            OK
          </Button>
        ]}
      >
        <div>
          <ReactPlayer url={props.video} controls width='100%' height='500px' />
        </div>
      </Modal>
    </>
  );
};

export default VideoModal;
