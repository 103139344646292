/* eslint-disable multiline-ternary */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, message, Popconfirm, Checkbox } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import Spinner from '../../../components/navigation/Spinner';
import { getIdToken } from 'firebase/auth';
import {
  createNotification,
  getNotificationById,
  patchNotification
} from '../../../services/notification.service';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { uploadFile } from '../../../services/file.service';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateNotification = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [notification, setNotification] = useState(null);
  const { firebaseUser } = useContext(Auth);
  const [loading, setLoading] = useState(true);
  const [isPersistent, setIsPersistent] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (params.id) {
        loadNotification(params.id);
      } else {
        setLoading(false);
      }
    }
  }, [user.account]);

  const loadNotification = async (id) => {
    const { status, data } = await getNotificationById(id, user.token);
    if (status === 200) {
      setNotification(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const fillForm = (notification) => {
    setIsPersistent(notification.isPersistent);
    form.setFieldsValue({
      name: notification.title,
      description: notification.message
    });
  };

  const deactivateNotification = async () => {
    if (notification) {
      let account;
      if (params.id) account = notification.account;
      else account = params.account;
      const { status } = await patchNotification(
        notification._id,
        { isActive: false },
        user.token
      );
      if (status === 200) {
        navigate('/dashboard/users/notifications/' + account);
        message.success(capitalize(t('notificationDeleted')));
      }
    }
  };

  const createNewNotification = async (data, token) => {
    let account;
    if (params.id) account = notification.account;
    else account = params.account;
    const formData = {
      title: data.name,
      message: data.description,
      isPersistent: isPersistent,
      account: account,
      sender: user.account._id
    };
    if (params.id) await patchNotification(params.id, formData, token);
    else await createNotification(formData, token);
    navigate('/dashboard/users/notifications/' + account);
  };

  const correctClave = async (e) => {
    await createNewNotification(e, user.token);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form
        form={form}
        onFinish={correctClave}
        style={{
          width: '100%',
          maxWidth: 400
        }}
      >
        <Form.Item>
          {notification ? (
            <h1>{capitalizeAll(t('editNotification'))}</h1>
          ) : (
            <h1>{capitalizeAll(t('createNotification'))}</h1>
          )}
        </Form.Item>
        <Form.Item
          name='name'
          rules={[
            {
              required: true,
              message: capitalize(t('title')) + ' ' + t('isRequired')
            }
          ]}
        >
          <Input
            addonBefore={capitalize(t('title'))}
            name='name'
            defaultValue={notification ? notification.title : ''}
            placeholder={capitalize(t('title'))}
          />
        </Form.Item>
        <div style={{ textAlign: 'left' }}>{capitalize(t('message'))}: </div>
        <Form.Item
          name='description'
          rules={[
            {
              required: true,
              message: capitalize(t('message')) + ' ' + t('isRequired')
            }
          ]}
        >
          <TextArea
            addonBefore={capitalize(t('message'))}
            allowClear={true}
            name='description'
            defaultValue={notification ? notification.message : ''}
            placeholder={capitalize(t('message'))}
          />
        </Form.Item>
        <Form.Item>
          <Checkbox
            name='isPersistent'
            defaultChecked={notification ? notification.isPersistent : false}
            onChange={(e) => setIsPersistent(e.target.checked)}
          >
            {capitalize(t('persistentNotificationAsk'))}
          </Checkbox>
        </Form.Item>
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {notification && (
            <Popconfirm
              title={capitalize(t('confirmDeleteNotification'))}
              onConfirm={deactivateNotification}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type='primary'
                  danger
                  className='delete-form-button'
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              style={{ marginRight: 10 }}
            >
              {notification
                ? capitalizeAll(t('saveChanges'))
                : capitalizeAll(t('createNotification'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateNotification;
