/* eslint-disable indent */
/* eslint-disable multiline-ternary */
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Spinner from '../../../components/navigation/Spinner';
import { getAccountById } from '../../../services/user.service';
import { getProgramByQueryWithExercises } from '../../../services/program.service';
import {
  getDeviceByQuery,
  getDeviceByQueryWithExercises
} from '../../../services/device.service';
import {
  getProgramSessionByQuery,
  patchProgramSession
} from '../../../services/programsession.service';
import { Button } from 'antd';
import {
  fadeIn,
  bounceInUp,
  slideInUp,
  fadeOut,
  zoomIn,
  slideOutUp,
  merge
} from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import Exercise from './Exercise';
import ExerciseSets from './ExerciseSets';
import ExerciseIso from './ExerciseIso';
import { LoadingOutlined, LogoutOutlined } from '@ant-design/icons';
import { capitalize } from '../../../utils/utils';
import { useTranslation } from 'react-i18next';
import ProgressControl from '../../../components/modals/ProgressControl';
import { use } from 'i18next';
import { getCenterById } from '../../../services/center.service';

const fadeUp = merge(fadeIn, slideInUp);

const styles = {
  fadeIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeIn, 'fadeIn')
  },
  fadeOut: {
    animation: 'x 0.5s',
    animationName: Radium.keyframes(fadeOut, 'fadeOut')
  },
  bounceInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(bounceInUp, 'bounceInUp')
  },
  slideInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp')
  },
  slideOutUp: {
    animation: 'x 4s',
    animationName: Radium.keyframes(slideOutUp, 'slideOutUp')
  },
  zoomIn: {
    animation: 'x 1s',
    animationName: Radium.keyframes(zoomIn, 'zoomIn')
  },
  fadeUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(fadeUp, 'fadeUp')
  }
};

const ClientExercises = (props) => {
  // const { user } = useContext(Auth);
  const params = useParams();
  const [account, setAccount] = useState();
  const [program, setProgram] = useState();
  const [center, setCenter] = useState();
  const [programSession, setProgramSession] = useState();
  const [device, setDevice] = useState();
  const [devices, setDevices] = useState([]);
  const [showExercise, setShowExercise] = useState();
  const [progressControlModalVisible, setProgressControlModalVisible] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);
  const navigate = useNavigate();
  const funRef = useRef(null);
  const { t } = useTranslation();

  const handleCloseProgressControlModal = () => {
    setProgressControlModalVisible(false);
  };

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        break;
      case 2:
        if (document.fullscreenEnabled) {
          document.documentElement.requestFullscreen();
        }
        break;
      case 3:
        break;
      default:
    }
  };

  useEffect(() => {
    let interval;
    if (!loading) {
      // set interval loadprogramsession every 5 seconds
      funRef.current = setInterval(() => {
        loadProgramSession();
      }, 5000);
      return () => {
        clearInterval(funRef.current);
      };
    } else {
      return () => {
        clearInterval(funRef.current);
      };
    }
  }, [loading]);

  useEffect(() => {
    // if (user.account && user.firebaseUser) {
    //
    loadAccount(params.account);
    // }
  }, []);

  const loadAccount = async (accountId) => {
    setLoading(true);
    const { status, data } = await getAccountById(accountId);
    if (status === 200) {
      setAccount(data.data);
      await loadCenter(data.data.center);
      await loadDevices(data.data.center);
      loadProgramSession();
    }
  };

  const loadCenter = async (centerId) => {
    const { status, data } = await getCenterById(centerId);
    if (status === 200) {
      setCenter(data.data);
    }
  };

  const loadDevices = async (centerId) => {
    const { status, data } = await getDeviceByQuery({ center: centerId });
    if (status === 200) {
      setDevices(data.data);
    }
  };

  const loadProgramSession = async (token) => {
    const { status, data } = await getProgramSessionByQuery(
      {
        center: params.center,
        account: params.account,
        isDeleted: false,
        isActive: true
      },
      token
    );
    if (status === 200 && data.data.length > 0) {
      setProgramSession(data.data[data.data.length - 1]);
      loadProgram(data.data[data.data.length - 1].program, token);
    } else {
      navigate(`/device/${params.center}/${params.device}`);
    }
  };

  const loadProgram = async (programId, token) => {
    const { status, data } = await getProgramByQueryWithExercises(
      { _id: programId },
      token
    );
    if (status === 200) {
      setProgram(data.data[data.data.length - 1]);
      loadDevice(params.device, params.center, token);
    }
  };

  const loadDevice = async (deviceId, centerId, token) => {
    const { status, data } = await getDeviceByQueryWithExercises(
      { identifier: deviceId, center: params.center },
      token
    );
    if (status === 200) {
      setDevice(data.data[data.data.length - 1]);
      setLoading(false);
    }
  };

  const closeAndArchiveSession = async () => {
    setLoadingButton(true);
    await patchProgramSession(programSession._id, {
      isActive: false,
      isDeleted: true,
      closed: new Date()
    });
    setLoadingButton(false);
    navigate(`/device/${params.center}/${params.device}`);
  };

  const handleShowExercise = async (state) => {
    setShowExercise(state);
    loadProgramSession();
  };

  const renderExercise = (exercise) => {
    const devicesWithExercise = [];
    devices.forEach((device) => {
      device.exercises.forEach((ex) => {
        if (ex === exercise._id) {
          devicesWithExercise.push(device);
        }
      });
    });
    // let bg = 'lightgray';
    let bg =
      'linear-gradient(0deg, rgba(62,62,62,1) 100%, rgba(193,193,193,1) 0%)';
    // let boxShadow = '5px 5px 0px 0px #9e9e9e, 10px 10px 0px 0px #aaaaaa, 15px 15px 0px 0px #bcbcbc, 20px 20px 0px 0px #cecece, 25px 25px 0px 0px #e5e5e5, 0px 0px 10px 1px rgba(0,0,0,0)';
    let clickable = false;
    // let done = false;
    device.exercises.forEach((deviceExercise) => {
      if (exercise._id === deviceExercise._id) {
        // bg = '#444';
        bg =
          'linear-gradient(0deg, rgba(193,193,193,1) 10%, rgba(62,62,62,1) 0%)';
        // boxShadow = '5px 5px 0px 0px #343434, 10px 10px 0px 0px #525252, 15px 15px 0px 0px #737373, 20px 20px 0px 0px #959595, 25px 25px 0px 0px #b3b3b3, 0px 0px 10px 1px rgba(0,0,0,0)';
        clickable = true;
      }
    });

    programSession.done.forEach((doneExercise) => {
      if (exercise._id === doneExercise) {
        bg =
          'linear-gradient(0deg, rgba(50, 122, 69,1) 100%, rgba(50, 122, 69,1) 40%)';
        // bg = 'green';
        // boxShadow = '5px 5px 0px 0px #00793b, 10px 10px 0px 0px #039d5b, 15px 15px 0px 0px #05c27c, 20px 20px 0px 0px #03e99f, 25px 25px 0px 0px #44febc, 0px 0px 10px 1px rgba(0,0,0,0)';
        clickable = false;
        // done = true;
      }
    });
    return (
      <>
        {/* <div style={[clickable && { cursor: 'pointer' }, { width: 200, height: 200, border: '1px solid #f5f5f5', borderRadius: 5, margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', fontSize: 24, background: bg, boxShadow: boxShadow }]} onClick={() => { */}
        <div
          style={[clickable && { cursor: 'pointer' }]}
          onClick={() => {
            if (clickable) {
              // TODO DESCOMENTAR
              // if (document.fullscreenEnabled) {
              //   document.documentElement.requestFullscreen();
              // }
              setShowExercise(exercise);
            }
          }}
        >
          <div className='exerciseCard' style={{ background: bg }}>
            {/* <div></div> */}
            <div style={{ color: 'white' }}>
              {exercise.name.slice(0, 25) +
                (exercise.name.length > 25 ? '...' : '')}
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', width: '100%' }}>{devicesWithExercise.map((dev, i) => { */}
            {/*  const ret = dev.identifier; */}
            {/*  if (i === devicesWithExercise.length - 1) { */}
            {/*    return ret; */}
            {/*  } else { */}
            {/*    return `${ret}, `; */}
            {/*  } */}
            {/* })}</div> */}
          </div>
          {/* {done && <div style={{ textAlign: 'center', fontSize: 12 }}>done</div>} */}
        </div>
      </>
    );
  };

  const renderExercises = (program) => {
    const exercisesActive = program.exercises.filter(
      (exercise) => exercise.isActive
    );
    return (
      <>
        <div
          style={[
            {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center'
            },
            styles.fadeIn
          ]}
        >
          {exercisesActive.map((exercise) => {
            return renderExercise(exercise);
          })}
        </div>
        <div
          style={[
            {
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              margin: 15
            },
            styles.slideInUp
          ]}
        >
          <button
            className={`basicButton${center.theme}`}
            style={{ margin: 15 }}
            onClick={() => {
              setProgressControlModalVisible(true);
            }}
          >
            {capitalize(t('progress'))}
          </button>
          {exercisesActive.length === programSession.done.length ? (
            <button
              disabled={loadingButton}
              className={`basicButton${center.theme}`}
              onClick={() => {
                closeAndArchiveSession();
              }}
              style={{ margin: 15 }}
            >
              {loadingButton ? <LoadingOutlined /> : capitalize(t('finish'))}
            </button>
          ) : (
            <button
              className={`basicButton${center.theme}`}
              onClick={() => {
                navigate(`/device/${params.center}/${params.device}`);
              }}
              style={{ margin: 15 }}
            >
              {capitalize(t('nextDevice'))}
            </button>
          )}
          {program && (
            <ProgressControl
              program={program._id}
              progressControlModalVisible={progressControlModalVisible}
              handleCloseProgressControlModal={handleCloseProgressControlModal}
            />
          )}
        </div>
      </>
    );
  };

  if (loading) {
    return <Spinner dark />;
  }

  if (showExercise) {
    if (showExercise.type === 'hist') {
      return (
        <Exercise
          exercise={showExercise}
          account={account}
          programSession={programSession}
          device={device}
          handleShowExercise={handleShowExercise}
        />
      );
    } else if (showExercise.type === 'isometric') {
      return (
        <ExerciseIso
          exercise={showExercise}
          account={account}
          programSession={programSession}
          device={device}
          handleShowExercise={handleShowExercise}
        />
      );
    } else if (showExercise.type === 'sets') {
      return (
        <ExerciseSets
          exercise={showExercise}
          account={account}
          programSession={programSession}
          device={device}
          handleShowExercise={handleShowExercise}
        />
      );
    } else if (showExercise.type === 'strength') {
      return (
        <Exercise
          exercise={showExercise}
          account={account}
          programSession={programSession}
          device={device}
          handleShowExercise={handleShowExercise}
        />
      );
    } else {
      return (
        <Exercise
          exercise={showExercise}
          account={account}
          programSession={programSession}
          device={device}
          handleShowExercise={handleShowExercise}
        />
      );
    }
  }

  return (
    <>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div
          className={`background-sub${center.theme}`}
          style={{ height: '100vh' }}
        >
          <div
            style={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              onClick={() =>
                navigate(`/device/${params.center}/${params.device}`)
              }
              style={{
                height: '100%',
                border: 0,
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center'
              }}
              ghost
            >
              <span
                className={`text-color${center.theme}`}
                style={{ fontSize: 18 }}
              >
                {account?.name} {account?.surname}
              </span>
              <LogoutOutlined
                className={`text-color${center.theme}`}
                style={{ fontSize: 24 }}
              />
            </Button>
          </div>
          <StyleRoot>
            <div
              className={`text-color${center.theme}`}
              style={{ textAlign: 'center', fontSize: 38, marginTop: 15 }}
            >
              {program &&
                program.name.slice(0, 25) +
                  (program.name.length > 25 ? '...' : '')}
            </div>
            {program && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  height: '70vh'
                }}
              >
                {renderExercises(program)}
              </div>
            )}
          </StyleRoot>
        </div>
      </div>
    </>
  );
};

export default ClientExercises;
