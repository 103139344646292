import React, { useEffect, useState } from 'react';

const IntervalChrono = ({ minutes = 1, onFiveSecondsLeft }) => {
  const [timeLeft, setTimeLeft] = useState(minutes * 60);

  useEffect(() => {
    setTimeLeft(minutes * 60);
    const timer = setInterval(() => {
      setTimeLeft((prev) => Math.max(prev - 1, 0));
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes]);

  useEffect(() => {
    if (timeLeft === 5 && onFiveSecondsLeft) {
      onFiveSecondsLeft();
    }
  }, [timeLeft, onFiveSecondsLeft]);

  useEffect(() => {
    setTimeLeft(minutes * 60);
  }, []);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  return (
    <div>
      <h1>{formatTime(timeLeft)}</h1>
    </div>
  );
};

export default IntervalChrono;
