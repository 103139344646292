/* eslint-disable no-sparse-arrays */
/* eslint-disable comma-style */
/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Col, Image, message, Row, Table } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  AudioOutlined,
  CopyOutlined,
  EditOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileZipOutlined,
  PlusOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import { getAccountById } from '../../../services/user.service';
import { getNotificationByQuery } from '../../../services/notification.service';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import 'moment/locale/es';
import { capitalize, capitalizeAll } from '../../../utils/utils';
moment.locale('es');

const Notifications = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState(null);
  const [account, setAccount] = useState(null);
  const [visible, setVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      loadAccount(params.account);
    }
  }, [user.account]);

  const loadNotifications = async (userId) => {
    const { status, data } = await getNotificationByQuery(
      { account: userId, isActive: true },
      user.token
    );
    if (status === 200) {
      data.data.forEach((notification) => {
        notification.key = notification._id;
      });
      setNotifications(data.data.reverse());
      setLoading(false);
    }
  };

  const loadAccount = async (userId) => {
    const { status, data } = await getAccountById(userId, user.token);
    if (status === 200) {
      setAccount(data.data);
      loadNotifications(params.account);
    }
  };

  const columns = [
    {
      title: capitalizeAll(t('notification')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {moment(record.timestamp.createdAt).format('LLL')}
          <br />
          {record.title}
          <p style={{ whiteSpace: 'pre' }}>{record.message}</p>
          <br />
          {record.isReaded
            ? capitalize(t('readed'))
            : capitalize(t('unReaded'))}
          <br />
          {record.isPersistent
            ? capitalize(t('persistent'))
            : capitalize(t('notPersistent'))}
          <br />
          <Button
            icon={<EditOutlined />}
            onClick={(e) => {
              navigate('/dashboard/users/notifications/edit/' + record._id);
            }}
          >
            {capitalize(t('edit'))}
          </Button>
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) =>
          a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('date')),
      dataIndex: ['timestamp.createdAt'],
      key: 'timestamp.createdAt',
      render: (text, record) =>
        moment(record.timestamp.createdAt).format('LLL'),
      sorter: {
        compare: (a, b) =>
          a.timestamp.createdAt.localeCompare(b.timestamp.createdAt),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('title')),
      key: 'title',
      // eslint-disable-next-line react/display-name
      render: (record) => <React.Fragment>{record.title}</React.Fragment>,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.title),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('message')),
      key: 'message',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          <p style={{ whiteSpace: 'pre' }}>{record.message}</p>
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.message.localeCompare(b.message),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('readed')),
      key: 'message',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          <p style={{ whiteSpace: 'pre' }}>
            {record.isReaded
              ? capitalize(t('readed'))
              : capitalize(t('unReaded'))}
          </p>
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.isReaded.localeCompare(b.isReaded),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('persistent')),
      key: 'message',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          <p style={{ whiteSpace: 'pre' }}>
            {record.isPersistent
              ? capitalize(t('persistent'))
              : capitalize(t('notPersistent'))}
          </p>
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.isReaded.localeCompare(b.isReaded),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('actions')),
      key: 'actions',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              icon={<EditOutlined />}
              onClick={(e) => {
                navigate('/dashboard/users/notifications/edit/' + record._id);
              }}
            >
              {capitalize(t('edit'))}
            </Button>
          </div>
        </React.Fragment>
      ),
      responsive: ['sm']
    }
  ];

  function onChange(pagination, filters, sorter, extra) {}

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <>
        <Button
          type='primary'
          shape='round'
          icon={<PlusOutlined />}
          size='large'
          onClick={() => navigate('add')}
          style={{ marginBottom: 18 }}
        >
          {capitalize(t('createNewNotification'))}
        </Button>
        <div>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <b>{account ? account.name : ''}</b>
        </div>
        <Table
          columns={columns}
          dataSource={notifications}
          onChange={onChange}
          // onRow={(record, rowIndex) => {
          //   return {
          //     onClick: event => { navigate('/dashboard/users/notifications/edit/' + record._id); }, // click row
          //     onDoubleClick: event => {}, // double click row
          //     onContextMenu: event => {}, // right button click row
          //     onMouseEnter: event => {}, // mouse enter row
          //     onMouseLeave: event => {} // mouse leave row
          //   };
          // }}
          rowClassName={'row-pointer'}
        />
        <br />
      </>
    );
  }
};

export default Notifications;
