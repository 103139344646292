import { methodGet, methodPatch, methodPost } from './api/methods';

export const getAllNotifications = (token) => {
  return methodGet('notification/all', '', token);
};

export const getNotificationById = (id, token) => {
  return methodGet('notification/' + id, '', token);
};

export const getNotificationByQuery = (req, token) => {
  return methodGet('notification', req, token);
};

export const createNotification = (data, token) => {
  return methodPost('notification/create', data, token);
};

export const patchNotification = (id, data, token) => {
  return methodPatch('notification/update?id=' + id, data, token);
};
