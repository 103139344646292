/* eslint-disable space-before-function-paren */
import React, { useContext, useEffect, useState } from 'react';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { Button, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import Spinner from '../../../components/navigation/Spinner';
import '../../../components/layout/layout.css';
import {
  getCenterByQuery,
  getCenterById
} from '../../../services/center.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { getIdToken } from 'firebase/auth';
import { useTranslation } from 'react-i18next';
import { capitalize } from '../../../utils/utils';

const Exercises = (props) => {
  const { user } = useContext(Auth);
  const navigate = useNavigate();
  const [exercises, setExercises] = useState(null);
  const [center, setCenter] = useState(null);
  const [histExercises, setHistExercises] = useState(null);
  const [isometricExercises, setIsometricExercises] = useState(null);
  const [strengthExercises, setStrengthExercises] = useState(null);
  const [setsExercises, setSetsExercises] = useState(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  const loadExercises = async (centerId) => {
    const { status, data } = await getExerciseByQuery(
      { center: centerId, isActive: true },
      user.token
    );
    if (status === 200) {
      data.data.forEach((exercise) => {
        exercise.key = exercise._id;
      });
      handleExerciseType(data.data);
      setExercises(data.data);
      setLoading(false);
    }
  };

  const handleExerciseType = (exercises) => {
    const histExercises = exercises.filter(
      (exercise) =>
        exercise.type === 'hist' ||
        !exercise.type ||
        exercise.type === '' ||
        exercise.type === null ||
        exercise.type === undefined
    );
    const isometricExercises = exercises.filter(
      (exercise) => exercise.type === 'isometric'
    );
    const strengthExercises = exercises.filter(
      (exercise) => exercise.type === 'strength'
    );
    const setsExercises = exercises.filter(
      (exercise) => exercise.type === 'sets'
    );
    setHistExercises(histExercises);
    setIsometricExercises(isometricExercises);
    setStrengthExercises(strengthExercises);
    setSetsExercises(setsExercises);
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery(
      { organizer: user.account._id },
      user.token
    );
    if (status === 200) {
      loadExercises(data.data[0]._id);
      setCenter(data.data[0]);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(
      user.account.center,
      user.token
    );
    if (status === 200) {
      setCenter(data.data);
      loadExercises(data.data._id);
    }
  };

  const histColumns = [
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
          <br />
          {record.concentric}
          <br />
          {record.eccentric}
          <br />
          {record.squeeze}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('concentric')),
      dataIndex: 'concentric',
      key: 'concentric',
      sorter: {
        compare: (a, b) => a.concentric.localeCompare(b.concentric),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('eccentric')),
      dataIndex: 'eccentric',
      key: 'eccentric',
      sorter: {
        compare: (a, b) => a.eccentric.localeCompare(b.eccentric),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('squeeze')),
      dataIndex: 'squeeze',
      key: 'squeeze',
      sorter: {
        compare: (a, b) => a.squeeze.localeCompare(b.squeeze),
        multiple: 1
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('settings')),
      dataIndex: 'settings',
      key: 'settings',
      sorter: {
        compare: (a, b) => a.settings.localeCompare(b.settings),
        multiple: 6
      },
      responsive: ['sm']
    }
  ];

  const isometricColumns = [
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => (
        <React.Fragment>
          {record.name}
          <br />
          {record.sets}
        </React.Fragment>
      ),
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('sets')),
      dataIndex: 'sets',
      key: 'sets',
      sorter: {
        compare: (a, b) => a.sets.localeCompare(b.sets),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('settings')),
      dataIndex: 'settings',
      key: 'settings',
      sorter: {
        compare: (a, b) => a.settings.localeCompare(b.settings),
        multiple: 6
      },
      responsive: ['sm']
    }
  ];

  const strengthColumns = [
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => <React.Fragment>{record.name}</React.Fragment>,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('settings')),
      dataIndex: 'settings',
      key: 'settings',
      sorter: {
        compare: (a, b) => a.settings.localeCompare(b.settings),
        multiple: 6
      },
      responsive: ['sm']
    }
  ];

  const setsColumns = [
    {
      title: capitalize(t('exercises')),
      // dataIndex: ['name', 'surname', 'email', 'phone'],
      key: 'all',
      // eslint-disable-next-line react/display-name
      render: (record) => <React.Fragment>{record.name}</React.Fragment>,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 5
      },
      responsive: ['xs']
    },
    {
      title: capitalize(t('name')),
      dataIndex: ['name'],
      key: '_id',
      render: (text, record) => record.name,
      sorter: {
        compare: (a, b) => a.name.localeCompare(b.name),
        multiple: 4
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('sets')),
      dataIndex: 'sets',
      key: 'sets',
      sorter: {
        compare: (a, b) => a.sets.localeCompare(b.sets),
        multiple: 3
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('reps')),
      dataIndex: 'reps',
      key: 'reps',
      sorter: {
        compare: (a, b) => a.reps.localeCompare(b.reps),
        multiple: 2
      },
      responsive: ['sm']
    },
    {
      title: capitalize(t('settings')),
      dataIndex: 'settings',
      key: 'settings',
      sorter: {
        compare: (a, b) => a.settings.localeCompare(b.settings),
        multiple: 6
      },
      responsive: ['sm']
    }
  ];

  function onChange(pagination, filters, sorter, extra) {}

  if (loading) {
    return <Spinner />;
  } else {
    return (
      <>
        <Button
          type='primary'
          shape='round'
          icon={<PlusOutlined />}
          size='large'
          onClick={() => navigate('add')}
          style={{ marginBottom: 18 }}
        >
          {capitalize(t('createNewExercise'))}
        </Button>
        <div>
          <h3>{t('hist').toUpperCase()}</h3>
        </div>
        <Table
          columns={histColumns}
          dataSource={histExercises}
          onChange={onChange}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                navigate('edit/' + record._id);
              }, // click row
              onDoubleClick: (event) => {}, // double click row
              onContextMenu: (event) => {}, // right button click row
              onMouseEnter: (event) => {}, // mouse enter row
              onMouseLeave: (event) => {} // mouse leave row
            };
          }}
          rowClassName={'row-pointer'}
        />
        <br />
        {isometricExercises.length > 0 && (
          <>
            <div>
              <h3>{t('isometric').toUpperCase()}</h3>
            </div>
            <Table
              columns={isometricColumns}
              dataSource={isometricExercises}
              onChange={onChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate('edit/' + record._id);
                  }, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {} // mouse leave row
                };
              }}
              rowClassName={'row-pointer'}
            />
            <br />
          </>
        )}
        {strengthExercises.length > 0 && (
          <>
            <div>
              <h3>{t('strength').toUpperCase()}</h3>
            </div>
            <Table
              columns={strengthColumns}
              dataSource={strengthExercises}
              onChange={onChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate('edit/' + record._id);
                  }, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {} // mouse leave row
                };
              }}
              rowClassName={'row-pointer'}
            />
            <br />
          </>
        )}
        {center.plan[7] === '1' && setsExercises.length > 0 && (
          <>
            <div>
              <h3>{t('table').toUpperCase()}</h3>
            </div>
            <Table
              columns={setsColumns}
              dataSource={setsExercises}
              onChange={onChange}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    navigate('edit/' + record._id);
                  }, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {} // mouse leave row
                };
              }}
              rowClassName={'row-pointer'}
            />
            <br />
          </>
        )}
      </>
    );
  }
};

export default Exercises;
