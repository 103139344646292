/* eslint-disable multiline-ternary */
import React, { useState, useContext, useEffect } from 'react';
import { Form, Input, Button, Alert, message, Popconfirm, Space } from 'antd';
import { Context as Auth } from '../../../services/auth/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getCenterByQuery,
  getCenterById
} from '../../../services/center.service';
import Spinner from '../../../components/navigation/Spinner';
import {
  createDevice,
  getDeviceById,
  patchDevice
} from '../../../services/device.service';
import { getExerciseByQuery } from '../../../services/exercise.service';
import { getIdToken } from 'firebase/auth';
import {
  DeleteOutlined,
  CaretUpOutlined,
  CaretDownOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';

const CreateDevice = (props) => {
  const { user } = useContext(Auth);
  const params = useParams();
  const [center, setCenter] = useState(null);
  const [exercises, setExercises] = useState([]);
  const [isometricExercises, setIsometricExercises] = useState([]);
  const [setsExercises, setSetsExercises] = useState([]);
  const [device, setDevice] = useState(null);
  const [selectedExercises, setSelectedExercises] = useState([]);
  const { firebaseUser } = useContext(Auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (user.account) {
      if (user.account.roles.indexOf('organizer') >= 0) {
        loadCenter();
      } else if (user.account.roles.indexOf('trainer') >= 0) {
        loadCenterId();
      }
    }
  }, [user.account]);

  useEffect(() => {
    if (
      device &&
      (exercises.length || isometricExercises.length || setsExercises.length)
    ) {
      const editExercisesArr = [];
      device.exercises.forEach((editExercise) => {
        exercises.forEach((allExercise) => {
          if (editExercise === allExercise._id) {
            editExercisesArr.push(allExercise);
          }
        });
        isometricExercises.forEach((allExercise) => {
          if (editExercise === allExercise._id) {
            editExercisesArr.push(allExercise);
          }
        });
        setsExercises.forEach((allExercise) => {
          if (editExercise === allExercise._id) {
            editExercisesArr.push(allExercise);
          }
        });
      });
      setSelectedExercises(editExercisesArr);
    }
  }, [device, exercises, isometricExercises, setsExercises]);

  const loadCenter = async () => {
    const { status, data } = await getCenterByQuery(
      { organizer: user.account._id },
      user.token
    );
    if (status === 200) {
      setCenter(data.data[0]);
      if (params.id) {
        await loadDevice(params.id, user.token);
      }
      await loadExercises(data.data[0]._id, user.token);
      setLoading(false);
    }
  };

  const loadCenterId = async () => {
    const { status, data } = await getCenterById(
      user.account.center,
      user.token
    );
    if (status === 200) {
      setCenter(data.data);
      if (params.id) {
        await loadDevice(params.id, user.token);
      }
      await loadExercises(data.data._id, user.token);
      setLoading(false);
    }
  };

  const loadDevice = async (id, token) => {
    const { status, data } = await getDeviceById(id, token);
    if (status === 200) {
      setDevice(data.data);
      fillForm(data.data);
      setLoading(false);
    }
  };

  const fillForm = (device) => {
    form.setFieldsValue({
      name: device.name,
      minWeight: device.minWeight,
      maxWeight: device.maxWeight,
      identifier: device.identifier
    });
  };

  const deactivateDevice = async () => {
    if (device) {
      const { status } = await patchDevice(
        device._id,
        { isActive: false },
        user.token
      );
      if (status === 200) {
        navigate('/dashboard/devices');
        message.success(capitalize(t('deviceDeleted')));
      }
    }
  };

  const loadExercises = async (center, token) => {
    const { status, data } = await getExerciseByQuery(
      { center: center, isActive: true },
      token
    );
    if (status === 200) {
      const isometric = data.data.filter(
        (exercise) => exercise.type === 'isometric'
      );
      const sets = data.data.filter((exercise) => exercise.type === 'sets');
      const others = data.data.filter(
        (exercise) => exercise.type !== 'isometric' && exercise.type !== 'sets'
      );
      setExercises(others.sort((a, b) => a.name.localeCompare(b.name)));
      setIsometricExercises(
        isometric.sort((a, b) => a.name.localeCompare(b.name))
      );
      setSetsExercises(sets.sort((a, b) => a.name.localeCompare(b.name)));
    }
  };

  const createNewDevice = async (data, token) => {
    let exercisesSelected;
    if (exercises.length || isometricExercises.length || setsExercises.length) {
      exercisesSelected = selectedExercises.map((exercise) => exercise._id);
    }
    const formData = {
      name: data.name,
      minWeight: data.minWeight,
      maxWeight: data.maxWeight,
      identifier: data.identifier,
      exercises: exercisesSelected,
      center: center._id
    };
    if (params.id) await patchDevice(params.id, formData, token);
    else await createDevice(formData, token);
    navigate('/dashboard/devices');
  };

  const correctClave = async (e) => {
    await createNewDevice(e, user.token);
  };

  if (loading) {
    return <Spinner />;
  }
  return (
    <div
      style={{
        background: '#fff',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex'
      }}
    >
      <Form
        form={form}
        onFinish={correctClave}
        style={{
          width: '100%',
          maxWidth: 400
        }}
      >
        <Form.Item>
          {device ? (
            <h1>{capitalizeAll(t('editDevice'))}</h1>
          ) : (
            <h1>{capitalizeAll(t('createDevice'))}</h1>
          )}
        </Form.Item>
        <Form.Item
          name='name'
          rules={[
            {
              required: true,
              message: capitalize(t('name')) + ' ' + t('isRequired')
            }
          ]}
        >
          <Input
            addonBefore={capitalize(t('name'))}
            name='name'
            defaultValue={device ? device.name : ''}
            placeholder={capitalize(t('name'))}
          />
        </Form.Item>
        <Form.Item
          name='minWeight'
          rules={[
            {
              required: true,
              message: capitalize(t('minWeight')) + ' ' + t('isRequired')
            }
          ]}
        >
          <Input
            addonBefore={capitalize(t('minWeight'))}
            name='minWeight'
            type={'number'}
            onWheel={(e) => e.target.blur()}
            defaultValue={device ? device.minWeight : ''}
            placeholder={capitalize(t('minWeight'))}
          />
        </Form.Item>
        <Form.Item
          name='maxWeight'
          rules={[
            {
              required: true,
              message: capitalize(t('maxWeight')) + ' ' + t('isRequired')
            }
          ]}
        >
          <Input
            addonBefore={capitalize(t('maxWeight'))}
            name='maxWeight'
            type={'number'}
            onWheel={(e) => e.target.blur()}
            defaultValue={device ? device.maxWeight : ''}
            placeholder={capitalize(t('maxWeight'))}
          />
        </Form.Item>
        <Form.Item
          name='identifier'
          rules={[
            {
              required: true,
              message: capitalize(t('identifier')) + ' ' + t('isRequired')
            }
          ]}
        >
          <Input
            addonBefore={capitalize(t('identifier'))}
            name='identifier'
            type={'number'}
            onWheel={(e) => e.target.blur()}
            defaultValue={device ? device.identifier : ''}
            placeholder={capitalize(t('identifier'))}
          />
        </Form.Item>

        <Form.Item>
          <div style={{ textAlign: 'left' }}>
            {capitalize(t('availableHistExercices'))}:
          </div>
          <div
            style={{
              border: '1px solid',
              borderRadius: '3px',
              borderColor: '#a6a6a6',
              maxHeight: 385,
              overflow: 'scroll'
            }}
          >
            {exercises.length ? (
              exercises.map((exercise) => {
                const isInSelected = selectedExercises.find(
                  (selectedExercise) => selectedExercise._id === exercise._id
                );
                return (
                  <Button
                    key={exercise._id}
                    type={isInSelected ? 'ghost' : 'primary'}
                    block
                    onClick={() => {
                      const validate = selectedExercises.filter(
                        (exerciseSel) => exerciseSel._id === exercise._id
                      );
                      if (!validate.length) {
                        const exercisesArr = [...selectedExercises];
                        exercisesArr.push(exercise);
                        setSelectedExercises(exercisesArr);
                      }
                    }}
                    style={{ margin: 2, width: '99%' }}
                  >
                    {exercise.name}
                  </Button>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: 'left' }}>
            {capitalize(t('availableIsometricExercices'))}:
          </div>
          <div
            style={{
              border: '1px solid',
              borderRadius: '3px',
              borderColor: '#a6a6a6',
              maxHeight: 385,
              overflow: 'scroll'
            }}
          >
            {isometricExercises.length ? (
              isometricExercises.map((exercise) => {
                const isInSelected = selectedExercises.find(
                  (selectedExercise) => selectedExercise._id === exercise._id
                );
                return (
                  <Button
                    key={exercise._id}
                    type={isInSelected ? 'ghost' : 'primary'}
                    block
                    onClick={() => {
                      const validate = selectedExercises.filter(
                        (exerciseSel) => exerciseSel._id === exercise._id
                      );
                      if (!validate.length) {
                        const exercisesArr = [...selectedExercises];
                        exercisesArr.push(exercise);
                        setSelectedExercises(exercisesArr);
                      }
                    }}
                    style={{ margin: 2, width: '99%' }}
                  >
                    {exercise.name}
                  </Button>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </Form.Item>

        {center.plan[7] === '1' && (
          <Form.Item>
            <div style={{ textAlign: 'left' }}>
              {capitalize(t('availableTableExercices'))}:
            </div>
            <div
              style={{
                border: '1px solid',
                borderRadius: '3px',
                borderColor: '#a6a6a6',
                maxHeight: 385,
                overflow: 'scroll'
              }}
            >
              {setsExercises.length ? (
                setsExercises.map((exercise) => {
                  const isInSelected = selectedExercises.find(
                    (selectedExercise) => selectedExercise._id === exercise._id
                  );
                  return (
                    <Button
                      key={exercise._id}
                      type={isInSelected ? 'ghost' : 'primary'}
                      block
                      onClick={() => {
                        const validate = selectedExercises.filter(
                          (exerciseSel) => exerciseSel._id === exercise._id
                        );
                        if (!validate.length) {
                          const exercisesArr = [...selectedExercises];
                          exercisesArr.push(exercise);
                          setSelectedExercises(exercisesArr);
                        }
                      }}
                      style={{ margin: 2, width: '99%' }}
                    >
                      {exercise.name}
                    </Button>
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </Form.Item>
        )}

        <Form.Item>
          {selectedExercises.length ? (
            <>
              <div style={{ textAlign: 'left' }}>
                {capitalize(t('selectedExercises'))}:
              </div>
              <div
                style={{
                  border: '1px solid',
                  borderRadius: '3px',
                  borderColor: '#a6a6a6'
                }}
              >
                {selectedExercises.length ? (
                  selectedExercises.map((exercise) => {
                    return (
                      <Alert
                        key={exercise._id}
                        message={exercise.name}
                        type='success'
                        closable
                        onClose={() => {
                          const validate = selectedExercises.filter(
                            (exerciseSel) => exerciseSel._id === exercise._id
                          );
                          if (validate.length) {
                            const exercisesArr = selectedExercises.filter(
                              (ex) => ex._id !== exercise._id
                            );
                            setSelectedExercises(exercisesArr);
                          }
                        }}
                        action={
                          <Space direction='horizontal'>
                            <Button
                              size='small'
                              type='ghost'
                              onClick={() => {
                                // move index up
                                const exercisesArr = [...selectedExercises];
                                const index = exercisesArr.indexOf(exercise);
                                if (index > 0) {
                                  const temp = exercisesArr[index - 1];
                                  exercisesArr[index - 1] = exercise;
                                  exercisesArr[index] = temp;
                                  setSelectedExercises(exercisesArr);
                                }
                              }}
                            >
                              <CaretUpOutlined />
                            </Button>
                            <Button
                              size='small'
                              type='ghost'
                              onClick={() => {
                                // move index down
                                const exercisesArr = [...selectedExercises];
                                const index = exercisesArr.indexOf(exercise);
                                if (index < exercisesArr.length - 1) {
                                  const temp = exercisesArr[index + 1];
                                  exercisesArr[index + 1] = exercise;
                                  exercisesArr[index] = temp;
                                  setSelectedExercises(exercisesArr);
                                }
                              }}
                            >
                              <CaretDownOutlined />
                            </Button>
                          </Space>
                        }
                        style={{ margin: 2, width: '99%' }}
                      />
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </Form.Item>
        <div
          style={{
            textAlign: 'right',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {device && (
            <Popconfirm
              title={capitalize(t('confirmDeleteDevice'))}
              onConfirm={deactivateDevice}
              // onCancel={cancel}
              okText={capitalize(t('yes'))}
              cancelText={capitalize(t('no'))}
            >
              <Form.Item>
                <Button
                  type='primary'
                  danger
                  className='delete-form-button'
                  style={{ marginRight: 10 }}
                  // onClick={deactivateUser}
                >
                  <DeleteOutlined />
                </Button>
              </Form.Item>
            </Popconfirm>
          )}
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              className='login-form-button'
              style={{ marginRight: 10 }}
            >
              {device
                ? capitalizeAll(t('saveChanges'))
                : capitalizeAll(t('createDevice'))}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};
export default CreateDevice;
