/* eslint-disable indent */
/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import Spinner from '../../../components/navigation/Spinner';
import {
  getSettingByQuery,
  patchSetting
} from '../../../services/setting.service';
import { Button, Col, Row } from 'antd';
import RepChrono from '../../../components/utils/RepChrono';
import ExerciseChrono from '../../../components/utils/ExerciseChrono';
import CheckButton from '../../../components/buttons/CheckButton';
import {
  createExerciseSession,
  getExerciseSessionByQuery
} from '../../../services/exercisesession.service';
import {
  getProgramSessionByQuery,
  patchProgramSession,
  getProgramSessionById
} from '../../../services/programsession.service';
import 'react-circular-progressbar/dist/styles.css';
import RadialSeparators from '../../../components/progress/RadialSeparators';
import bstLogo from '../../../assets/images/logoBST.png';
import {
  SettingOutlined,
  LogoutOutlined,
  LoadingOutlined,
  VideoCameraOutlined
} from '@ant-design/icons';
import ConfigExercise from './ConfigExercise';
import FullScreenMessageModal from '../../../components/modals/FullScreenMessage';
import moment from 'moment/moment';
import 'moment/locale/es';
import { getCenterById } from '../../../services/center.service';
import { useTranslation } from 'react-i18next';
import { capitalize, capitalizeAll } from '../../../utils/utils';
import beep1 from '../../../assets/sounds/beep1.mp3';
import beep2 from '../../../assets/sounds/beep2.mp3';
import beep3 from '../../../assets/sounds/beep3.mp3';
import useSound from 'use-sound';
import { useNavigate, useParams } from 'react-router-dom';
import SettingsModal from '../../../components/modals/SettingsModal';
import VideoModal from '../../../components/modals/VideoModal';
import IntervalChrono from '../../../components/utils/IntervalChrono';
import { set } from 'date-fns';
import { current } from '@reduxjs/toolkit';
import { ca } from 'date-fns/locale';

moment.locale('es');

const ExerciseSets = (props) => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState();
  const [progressBar, setProgressBar] = useState(0);
  const [reps, setReps] = useState(0);
  const [progressSqueeze, setProgressSqueeze] = useState('255, 0, 0');
  const [clockSqueeze, setClockSqueeze] = useState('#000');
  const [startExercise, setStartExercise] = useState(false);
  const [showResume, setShowResume] = useState(false);
  const [noSettings, setNoSettings] = useState(true);
  const [showSettingsConfig, setShowSettingsConfig] = useState(false);
  const [showSettingsConfigModal, setShowSettingsConfigModal] = useState(false);
  const [exerciseTime, setExerciseTime] = useState();
  const [center, setCenter] = useState();
  const [dontStopTimer, setDontStopTimer] = useState(false);
  // const [lastProgramSession, setLastProgramSession] = useState(null);
  const [lastExerciseSession, setLastExerciseSession] = useState(null);
  const [messageModalVisible, setMessageModalVisible] = useState(false);
  const [videoModalVisible, setVideoModalVisible] = useState(false);
  const [currentSet, setCurrentSet] = useState();
  const [setsInterval, setSetsInterval] = useState();
  const [weight, setWeight] = useState(0);
  const [nextWeight, setNextWeight] = useState(0);
  const [messageModal, setMessageModal] = useState('READY');
  const [loadingButton, setLoadingButton] = useState(false);
  const [newSettings, setNewSettings] = useState();
  const [resumeButtonsState, setResumeButtonsState] = useState({
    NS: false,
    NF: false,
    MT: false,
    AP: false
  });
  const [asistedReps, setAsistedReps] = useState(0);
  const [discountReps, setDiscountReps] = useState(0);
  const [percent, setPercent] = useState(0);
  const [transition, setTransition] = useState(0);
  const [squeeze, setSqueeze] = useState(false);
  const [countDown, setCountDown] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [playBeep2] = useSound(beep2);
  const repChronoRef = useRef();

  const handleClick = (e) => {
    switch (e.detail) {
      case 1:
        break;
      case 2:
        if (document.fullscreenEnabled) {
          document.documentElement.requestFullscreen();
        }
        break;
      case 3:
        break;
      default:
    }
  };

  useEffect(() => {
    if (messageModalVisible) {
      setStartExercise(true);
      if (currentSet === undefined || currentSet === null) {
        setCurrentSet(settings.sets[0]);
      }
      // setMessageModal(t('ready').toUpperCase());
      setMessageModal(5);
      let time = 4;
      const timer = setInterval(() => {
        setMessageModal(time);
        time--;
        if (time === -1) {
          clearInterval(timer);
          setMessageModalVisible(false);
          playBeep2();
          setPercent(0);
          setCountDown(false);
          setMessageModal('READY');
          setDontStopTimer(false);
        }
      }, 1000);
    }
  }, [messageModalVisible]);

  useEffect(() => {
    // if (props.user.account && props.user.firebaseUser) {
    loadSettings(props.account._id, props.exercise._id);
    // }
  }, [showSettingsConfig]);

  const handleCloseSettingsModal = () => {
    setShowSettingsConfigModal(false);
  };

  const handleSaveSettings = (settings) => {
    setNewSettings(settings);
    setShowSettingsConfigModal(false);
  };

  const getUpgrade = (value) => {
    const percent = (settings?.progress * value) / 100;
    return Math.ceil(percent / props.device.minWeight) * props.device.minWeight;
  };

  // useEffect(() => {
  //   if (lastExerciseSession) {
  //     let finalWeight = lastExerciseSession.weight;
  //     if (lastExerciseSession.passTul === 2 && !lastExerciseSession.NS) {
  //       finalWeight = lastExerciseSession.weight + getUpgrade(lastExerciseSession.weight);
  //     } else if (lastExerciseSession.passTul === 1) {
  //       finalWeight = lastExerciseSession.weight - getUpgrade(lastExerciseSession.weight);
  //     }
  //     setWeight(finalWeight);
  //   }
  // }, [lastExerciseSession]);

  useEffect(() => {
    if (settings) {
      let newWeight;
      if (settings.weight) {
        newWeight = settings.weight.toFixed(2);
      } else {
        newWeight = 0;
      }
      if (newWeight % 1 === 0) {
        newWeight = parseFloat(newWeight).toFixed(0);
      }
      setWeight(parseFloat(newWeight));
      setNextWeight(parseFloat(newWeight));
    }
  }, [settings]);

  const loadSettings = async (accountId, exerciseId) => {
    setLoading(true);
    const { status, data } = await getSettingByQuery({
      account: accountId,
      exercise: exerciseId,
      isActive: true
    });

    if (status === 200) {
      if (data.data.length > 0) {
        setNewSettings(data.data[0].setting);
        setSettings(data.data[data.data.length - 1]);
        setNoSettings(false);
        await loadCenter();
        await loadLastProgramSession();
      } else {
        await loadCenter();
        setNoSettings(true);
      }
      setLoading(false);
    }
  };

  const loadCenter = async () => {
    const { status, data } = await getCenterById(props.account.center);
    if (status === 200) {
      setCenter(data.data);
    }
  };

  const loadLastProgramSession = async () => {
    const { status, data } = await getProgramSessionByQuery({
      account: props.account._id
    });
    if (status === 200) {
      if (data.data.length > 0) {
        const lastSession = data.data
          .reverse()
          .find((pSession) =>
            pSession.done.some((ex) => ex === props.exercise._id)
          );
        if (lastSession) {
          // setLastProgramSession(lastSession);
          await loadLastExerciseSession(lastSession._id);
        }
      }
    }
  };

  const loadLastExerciseSession = async (programSessionId) => {
    const { status, data } = await getExerciseSessionByQuery({
      account: props.account._id,
      exercise: props.exercise._id,
      programSession: programSessionId
    });

    if (status === 200) {
      if (data.data.length > 0) {
        setLastExerciseSession(data.data[data.data.length - 1]);
      }
    }
  };

  const timeFormat = (date) => {
    if (!date) return '00:00';

    let mm = date.getMinutes();
    let ss = date.getSeconds();

    ss = ss < 10 ? '0' + ss : ss;
    mm = mm < 10 ? '0' + mm : mm;

    return `${mm}:${ss}`;
  };

  const handleResumeButtons = (id, state) => {
    const resButtons = { ...resumeButtonsState };
    resButtons[id] = state;
    setResumeButtonsState({ ...resButtons });
  };

  const handleAsistedReps = (num) => {
    const asisted = asistedReps + num;
    const repTime = settings.concentric + settings.eccentric + settings.squeeze;
    setExerciseTime(
      moment(exerciseTime)
        .subtract(num * repTime, 's')
        .toDate()
    );
    setReps(reps - num);
    setAsistedReps(asisted);
  };

  const handleDiscountReps = (num) => {
    const discount = discountReps + num;
    const repTime = settings.concentric + settings.eccentric + settings.squeeze;
    setExerciseTime(
      moment(exerciseTime)
        .subtract(num * repTime, 's')
        .toDate()
    );
    setReps(reps - num);
    setDiscountReps(discount);
  };

  const getSeconds = (time) => {
    const minutes = moment(time).format('mm');
    const seconds = moment(time).format('ss');
    return parseInt(minutes) * 60 + parseInt(seconds);
  };

  const calculatePassTul = () => {
    let minTul = 80;
    let maxTul = 100;
    switch (settings.fiber) {
      case 'SHORT':
        minTul = 80;
        maxTul = 100;
        break;
      case 'MEDIUM':
        minTul = 100;
        maxTul = 130;
        break;
      case 'LONG':
        minTul = 130;
        maxTul = 160;
        break;
      default:
        minTul = 80;
        maxTul = 100;
        break;
    }
    const time = getSeconds(exerciseTime);
    // const repTime = settings.concentric + settings.eccentric + settings.squeeze;
    // const discountTime = discountReps * repTime;
    // const asistedTime = asistedReps * repTime;
    // const totalTime = time - discountTime - asistedTime;
    if (time < minTul) {
      return 1;
    } else if (time > maxTul) {
      return 2;
    } else {
      return 0;
    }
  };

  const submitResume = async () => {
    setLoadingButton(true);
    const options = { ...resumeButtonsState };
    // const passTul = calculatePassTul();
    const passTul = 0;
    const payload = {
      ...options,
      reps: reps,
      sets: settings.sets,
      type: 'sets',
      incomplete: asistedReps,
      passTul: passTul,
      account: props.account._id,
      exercise: props.exercise._id,
      programSession: props.programSession._id,
      weight: weight,
      settings: settings._id
    };
    if (settings) {
      await patchSetting(settings._id, {
        weight: nextWeight,
        setting: newSettings
      });
    }
    await createExerciseSession(payload);
    const updatedProgramSession = await getProgramSessionById(
      props.programSession._id
    );
    await patchProgramSession(props.programSession._id, {
      done: [...updatedProgramSession.data.data.done, props.exercise._id]
    });
    setStartExercise(true);
    setShowResume(false);
    setLoadingButton(false);
    setReps(0);
    setAsistedReps(0);
    props.handleShowExercise(null);
  };

  if (loading) {
    return <Spinner dark />;
  }

  const renderResume = () => {
    return (
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div
          className={`background-sub${center.theme} text-color${center.theme}`}
          style={{
            height: '100vh',
            justifyContent: 'space-between',
            paddingBottom: 40,
            display: 'flex',
            flexDirection: 'column',
            wrap: 'nowrap'
          }}
        >
          <Row>
            <div
              style={{
                padding: 0,
                display: 'flex',
                width: '100%',
                marginBottom: 15,
                alignItems: 'center',
                textAlign: 'right',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                onClick={() =>
                  navigate(`/device/${params.center}/${params.device}`)
                }
                style={{
                  height: '100%',
                  border: 0,
                  boxShadow: 'none',
                  display: 'flex',
                  alignItems: 'center'
                }}
                ghost
              >
                <span
                  className={`text-color${center.theme}`}
                  style={{ fontSize: 18 }}
                >
                  {props.account?.name} {props.account?.surname}
                </span>
                <LogoutOutlined
                  className={`text-color${center.theme}`}
                  style={{ fontSize: 24 }}
                />
              </Button>
            </div>
            <Col style={{ width: '100%', textAlign: 'center' }}>
              <div
                style={{
                  background: 'darkred',
                  width: '100%',
                  paddingTop: 10,
                  paddingBottom: 10
                }}
              >
                <div
                  className='exCompleted'
                  style={{
                    fontSize: 36,
                    color: 'white',
                    background: 'rgba(34,34,34,1)'
                  }}
                >
                  {capitalizeAll(t('exerciseCompleted'))}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  wrap: 'nowrap',
                  marginTop: 25,
                  justifyContent: 'center'
                }}
              >
                {/* <CheckButton
                  text={t('ns').toUpperCase()}
                  id={'NS'}
                  width={'60px'}
                  handleResumeButtons={handleResumeButtons}
                /> */}
                <CheckButton
                  text={t('nf').toUpperCase()}
                  id={'NF'}
                  width={'60px'}
                  handleResumeButtons={handleResumeButtons}
                />
                <CheckButton
                  text={t('mt').toUpperCase()}
                  id={'MT'}
                  width={'60px'}
                  handleResumeButtons={handleResumeButtons}
                />
                <CheckButton
                  text={t('ap').toUpperCase()}
                  id={'AP'}
                  width={'60px'}
                  handleResumeButtons={handleResumeButtons}
                />
              </div>
            </Col>
          </Row>
          <Col>
            <div
              className={`text-color${center.theme}`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 60,
                fontSize: 32
              }}
            >
              <button
                className={`basicButton${center.theme}`}
                style={{ fontSize: 32, height: '100%', margin: 0 }}
                onClick={() => {
                  let newWeight = (
                    parseFloat(nextWeight) - props.device.minWeight
                  ).toFixed(2);
                  if (newWeight % 1 === 0) {
                    newWeight = parseFloat(newWeight).toFixed(0);
                  }
                  setNextWeight(parseFloat(newWeight));
                }}
              >
                -
              </button>
              <div style={{ marginRight: 15, marginLeft: 15, width: 150 }}>
                {nextWeight} {props.exercise.weightMeasure}
              </div>
              <button
                className={`basicButton${center.theme}`}
                style={{ fontSize: 32, height: '100%', margin: 0 }}
                onClick={() => {
                  let newWeight = (
                    parseFloat(nextWeight) + props.device.minWeight
                  ).toFixed(2);
                  if (newWeight % 1 === 0) {
                    newWeight = parseFloat(newWeight).toFixed(0);
                  }
                  setNextWeight(parseFloat(newWeight));
                }}
              >
                +
              </button>
            </div>
          </Col>
          <Row align='center' justify={'space-around'}>
            <Col span={4} style={{ fontSize: 24 }}>
              <div
                style={{ fontSize: 40, cursor: 'pointer' }}
                onClick={() => setShowSettingsConfigModal(true)}
              >
                <SettingOutlined />
              </div>
              {capitalize(t('sets'))}: {settings.sets.length}
              <br />
              {capitalize(t('weight'))}: {weight}
              <br />
            </Col>
            <Col
              span={4}
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 10,
                  alignItems: 'flex-end'
                }}
              >
                <button
                  className={`basicButton${center.theme}`}
                  style={{ color: 'red' }}
                  onClick={() => {
                    setPercent(0);
                    // setTransition(settings.concentric);
                    // setProgressBar(0);
                    setShowResume(false);
                    // setMessageModalVisible(true);
                  }}
                >
                  {capitalizeAll(t('retry'))}
                </button>
              </div>
            </Col>
            <Col span={4} style={{ display: 'flex', alignItems: 'flex-end' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button
                  className={`basicButton${center.theme} text-submit${center.theme}`}
                  disabled={loadingButton}
                  onClick={submitResume}
                >
                  {loadingButton ? (
                    <LoadingOutlined />
                  ) : (
                    capitalizeAll(t('saveResults'))
                  )}
                </button>
              </div>
            </Col>
          </Row>

          {messageModalVisible && (
            <FullScreenMessageModal
              messageModalVisible={messageModalVisible}
              setMessageModalVisible={setMessageModalVisible}
              message={messageModal}
            />
          )}
          {settings && (
            <SettingsModal
              showSettingsModal={showSettingsConfigModal}
              center={center}
              handleCloseSettingsModal={handleCloseSettingsModal}
              settings={newSettings}
              handleSaveSettings={handleSaveSettings}
            />
          )}
        </div>
      </div>
    );
  };

  const renderInfo = () => {
    if (settings) {
      return (
        <>
          <div
            className={`background-main${center.theme}`}
            onClick={handleClick}
          >
            <div
              className={`background-sub${center.theme}`}
              style={{
                height: '100vh',
                paddingLeft: 15,
                paddingRight: 15,
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
                wrap: 'nowrap'
              }}
            >
              <div
                style={{
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  onClick={() =>
                    navigate(`/device/${params.center}/${params.device}`)
                  }
                  style={{
                    height: '100%',
                    border: 0,
                    boxShadow: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 0
                  }}
                  ghost
                >
                  <span
                    className={`text-color${center.theme}`}
                    style={{ fontSize: 18 }}
                  >
                    {props.account?.name} {props.account?.surname}
                  </span>
                  <LogoutOutlined
                    className={`text-color${center.theme}`}
                    style={{ fontSize: 24 }}
                  />
                </Button>
              </div>
              <Row justify={'space-around'} align={'middle'}>
                <Col className={`text-shadow${center.theme} exercise-name`}>
                  <div>
                    {props.exercise.name.slice(0, 25) +
                      (props.exercise.name.length > 25 ? '...' : '')}
                  </div>

                  {props.exercise.video && (
                    <div
                      style={{
                        fontSize: 52,
                        height: '100%',
                        cursor: 'pointer'
                      }}
                      onClick={(e) => setVideoModalVisible(true)}
                    >
                      <VideoCameraOutlined />
                    </div>
                  )}
                </Col>
              </Row>
              <Row justify={'space-around'} align={'middle'} wrap={false}>
                <Col style={{ textAlign: 'center', width: '100%' }}>
                  <div className={`text-shadow${center.theme} column-info`}>
                    <h2 className={`text-color${center.theme}`}>
                      {t('settings').toUpperCase()}
                    </h2>
                    <p
                      style={{
                        whiteSpace: 'pre-wrap',
                        overflowWrap: 'anywhere'
                      }}
                    >
                      {settings.setting}
                    </p>
                  </div>
                </Col>
                <Col style={{ textAlign: 'center' }}>
                  <div className={'column-center'}>
                    <div className={'column-img'}>
                      <img
                        className={'column-img-img'}
                        src={center.logo}
                        alt={'logo'}
                        onClick={() => setMessageModalVisible(true)}
                      />
                    </div>
                    <div
                      className={`text-color${center.theme}`}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 60,
                        fontSize: 32
                      }}
                    >
                      <button
                        className={`basicButton${center.theme}`}
                        style={{ fontSize: 32, height: '100%', margin: 0 }}
                        onClick={() => {
                          let newWeight = (
                            parseFloat(weight) - props.device.minWeight
                          ).toFixed(2);
                          if (newWeight % 1 === 0) {
                            newWeight = parseFloat(newWeight).toFixed(0);
                          }
                          setWeight(parseFloat(newWeight));
                          setNextWeight(parseFloat(newWeight));
                        }}
                      >
                        -
                      </button>
                      <div
                        style={{ marginRight: 15, marginLeft: 15, width: 150 }}
                      >
                        {weight} {props.exercise.weightMeasure}
                      </div>
                      <button
                        className={`basicButton${center.theme}`}
                        style={{ fontSize: 32, height: '100%', margin: 0 }}
                        onClick={() => {
                          let newWeight = (
                            parseFloat(weight) + props.device.minWeight
                          ).toFixed(2);
                          if (newWeight % 1 === 0) {
                            newWeight = parseFloat(newWeight).toFixed(0);
                          }
                          setWeight(parseFloat(newWeight));
                          setNextWeight(parseFloat(newWeight));
                        }}
                      >
                        +
                      </button>
                    </div>
                    <div
                      onClick={() => setMessageModalVisible(true)}
                      style={{
                        color: 'red',
                        fontSize: '24px',
                        textShadow:
                          '#2A3D4E 1px 1px,#2A3D4E -0px 0px,#2A3D4E -1px 1px,#2A3D4E -2px 2px,#2A3D4E -3px 3px,#2A3D4E -4px 4px,#2A3D4E -5px 5px,#2A3D4E -6px 6px,#2A3D4E -7px 7px,#2A3D4E -8px 8px,#2A3D4E -9px 9px'
                      }}
                    >
                      <button
                        className={`basicButton${center.theme}`}
                        style={{ width: '100%', marginTop: 15 }}
                      >
                        {t('start').toUpperCase()}
                      </button>
                    </div>
                  </div>
                </Col>
                <Col style={{ textAlign: 'center', width: '100%' }}>
                  <div className={`text-shadow${center.theme} column-last`}>
                    <Row justify={'center'}>
                      {t('lastSession').toUpperCase()}
                    </Row>
                    {lastExerciseSession ? (
                      <>
                        <Row justify={'center'}>
                          {moment(
                            lastExerciseSession.timestamp.createdAt
                          ).format('L')}
                        </Row>
                        <Row justify={'center'}>
                          <div>
                            {lastExerciseSession.sets.length}{' '}
                            {t('sets').toUpperCase()}
                          </div>
                        </Row>
                        <Row justify={'center'}>
                          {lastExerciseSession.weight}{' '}
                          {props.exercise.weightMeasure}
                        </Row>
                        <Row justify={'center'} style={{ color: 'red' }}>
                          {lastExerciseSession.AP && <> AP </>}
                          {lastExerciseSession.NF && <> NF </>}
                          {lastExerciseSession.NS && <> NS </>}
                          {lastExerciseSession.MT && <> MT </>}
                        </Row>
                      </>
                    ) : (
                      capitalize(t('noLastSession'))
                    )}
                    <Row justify={'center'}>
                      <div
                        style={{ fontSize: 40, cursor: 'pointer' }}
                        onClick={() => setShowSettingsConfig(true)}
                      >
                        <SettingOutlined />
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div></div>
            </div>
          </div>
          {messageModalVisible && (
            <FullScreenMessageModal
              messageModalVisible={messageModalVisible}
              setMessageModalVisible={setMessageModalVisible}
              message={messageModal}
            />
          )}

          {videoModalVisible && (
            <VideoModal
              videoModalVisible={videoModalVisible}
              setVideoModalVisible={setVideoModalVisible}
              video={props.exercise.video}
            />
          )}
        </>
      );
    }
  };

  const renderNoSettings = () => {
    setShowSettingsConfig(true);
    return (
      <>
        <div
          style={{
            background:
              '#222 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANklEQVQYV2NkwAMEBQX/M+KSB0m+f/+eEasCmCRIM4YCZEkMBeiSKAqwScIV4JIEK8AnCVIAAKedHTuzxDwjAAAAAElFTkSuQmCC) repeat'
          }}
          onClick={handleClick}
        >
          <div
            style={{
              height: '100vh',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'column',
              wrap: 'nowrap',
              background:
                'linear-gradient(145deg, rgba(255,255,255,0.19371498599439774) 100%, rgba(122,122,122,0.2945553221288515) 50%, rgba(64,64,64,0.50) 50%)'
            }}
          >
            <Row justify={'space-around'} align={'middle'}>
              <Col className={`text-shadow${center.theme} exercise-name`}>
                {props.exercise.name.slice(0, 25) +
                  (props.exercise.name.length > 25 ? '...' : '')}
              </Col>
            </Row>
            <Row justify={'space-around'} align={'middle'} wrap={false}>
              <Col style={{ textAlign: 'center', width: '100%' }}>
                <div className={`text-shadow${center.theme} column-info`}>
                  <h2 className={`text-color${center.theme}`}>
                    {t('settings').toUpperCase()}
                  </h2>
                  <p style={{ whiteSpace: 'pre' }}>
                    This is the first time <br /> user do this exercise.
                    <br /> Please set your settings.
                  </p>
                  <div
                    style={{ fontSize: 40, cursor: 'pointer' }}
                    onClick={() => setShowSettingsConfig(true)}
                  >
                    <SettingOutlined />
                  </div>
                </div>
              </Col>
              <Col style={{ textAlign: 'center' }}>
                <div className={'column-center'}>
                  <div className={'column-img'}>
                    <img
                      className={'column-img-img'}
                      src={center.logo}
                      alt={'logo'}
                      onClick={() => setMessageModalVisible(true)}
                    />
                  </div>
                  <div
                    onClick={() => setShowSettingsConfig(true)}
                    style={{
                      marginTop: 50,
                      cursor: 'pointer',
                      color: 'red',
                      fontSize: '50px',
                      textShadow:
                        '#2A3D4E 1px 1px,#2A3D4E -0px 0px,#2A3D4E -1px 1px,#2A3D4E -2px 2px,#2A3D4E -3px 3px,#2A3D4E -4px 4px,#2A3D4E -5px 5px,#2A3D4E -6px 6px,#2A3D4E -7px 7px,#2A3D4E -8px 8px,#2A3D4E -9px 9px'
                    }}
                  >
                    Config
                  </div>
                </div>
              </Col>
              <Col style={{ textAlign: 'center', width: '100%' }}>
                <div
                  style={{
                    paddingTop: 40,
                    paddingBottom: 40,
                    color: 'white',
                    fontSize: '30px',
                    textShadow:
                      '#2A3D4E 1px 1px,#2A3D4E -0px 0px,#2A3D4E -1px 1px,#2A3D4E -2px 2px,#2A3D4E -3px 3px,#2A3D4E -4px 4px,#2A3D4E -5px 5px,#2A3D4E -6px 6px,#2A3D4E -7px 7px,#2A3D4E -8px 8px,#2A3D4E -9px 9px'
                  }}
                >
                  {/* <Row justify={'center'}> */}
                  {/*  LAST SESSION */}
                  {/* </Row> */}
                  {/* <Row justify={'center'}><del>12/12/12</del></Row> */}
                  {/* <Row justify={'center'}> */}
                  {/*  <div> */}
                  {/*    <del>02:00</del> */}
                  {/*  </div> */}
                  {/*  <div> */}
                  {/*    <del>9 REP</del> */}
                  {/*  </div> */}
                  {/* </Row> */}
                  {/* <Row justify={'center'}><del>142lbs</del></Row> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  const handleProgress = (miliseconds, total, phase) => {
    // if (miliseconds % 50 !== 0) return;
    if (miliseconds === 0) {
      setPercent(0);
      // setTransition(settings.concentric);
      // setProgressBar(0);
    }
    if (phase === 'squeeze') {
      if (center.theme === '-light') {
        setProgressSqueeze('0, 0, 0');
      } else {
        setProgressSqueeze('250, 250, 250');
      }
      setSqueeze(true);
      if (center.theme !== '-light') {
        setClockSqueeze('#000');
      }
    } else if (phase === 'eccentric') {
      setProgressSqueeze('255, 0, 0');
      setSqueeze(false);
      setClockSqueeze('#fff');
      //
      const percent = 100 - (100 * Number(miliseconds)) / total;
      setProgressBar(parseFloat(percent.toFixed(3)));
      if (percent !== 0) {
        setTransition(settings.eccentric);
        setPercent(0);
      }
    } else if (phase === 'concentric') {
      setProgressSqueeze('255, 0, 0');
      setClockSqueeze('#fff');
      setSqueeze(false);
      const percent = (100 * Number(miliseconds)) / total;
      setProgressBar(percent);
      if (percent !== 100) {
        setTransition(currentSet.time);
        setPercent(100);
      }
    }
  };

  const handleExerciseTime = (time) => {
    setExerciseTime(time);
  };

  const handleReps = () => {
    const update = reps + 1;
    if (update === parseInt(currentSet.reps)) {
      const nextSet = settings.sets.indexOf(currentSet) + 1;
      if (nextSet < settings.sets.length) {
        setSetsInterval(true);
        setCurrentSet(settings.sets[nextSet]);
        setDontStopTimer(true);
        setPercent(0);
        setProgressBar(0);
        setReps(0);
      } else {
        setShowResume(true);
        setDontStopTimer(false);
        setStartExercise(false);
        setCountDown(true);
      }
    } else {
      setReps(update);
    }
  };

  if (showSettingsConfig) {
    let editSettings = null;
    if (!noSettings) {
      editSettings = settings;
    }
    return (
      <ConfigExercise
        exercise={props.exercise}
        account={props.account}
        setShowSettingsConfig={setShowSettingsConfig}
        setNoSettings={setNoSettings}
        editSettings={editSettings}
      />
    );
  }

  if (noSettings) {
    return renderNoSettings();
  }

  if (showResume) {
    return <>{renderResume()}</>;
  }

  if (!startExercise) {
    return <>{renderInfo()}</>;
  }

  return (
    <>
      <div className={`background-main${center.theme}`} onClick={handleClick}>
        <div
          className={`background-sub${center.theme}`}
          style={{
            height: '100vh',
            justifyContent: 'space-between',
            paddingLeft: 30,
            paddingRight: 30,
            display: 'flex',
            flexDirection: 'column',
            wrap: 'nowrap'
          }}
          onClick={() => {
            setShowResume(true);
            setDontStopTimer(false);
            setStartExercise(false);
            setCountDown(true);
          }}
        >
          <div
            style={{
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              onClick={() =>
                navigate(`/device/${params.center}/${params.device}`)
              }
              style={{
                height: '100%',
                border: 0,
                boxShadow: 'none',
                display: 'flex',
                alignItems: 'center'
              }}
              ghost
            >
              <span
                className={`text-color${center.theme}`}
                style={{ fontSize: 18 }}
              >
                {props.account?.name} {props.account?.surname}
              </span>
              <LogoutOutlined
                className={`text-color${center.theme}`}
                style={{ fontSize: 24 }}
              />
            </Button>
          </div>
          {/* <div style={{ background: 'linear-gradient(145deg, rgba(255,255,255,0.19371498599439774) 21%, rgba(122,122,122,0.2945553221288515) 50%, rgba(64,64,64,0.50) 50%)' }}> */}
          <Row justify={'space-around'} align={'middle'}>
            <Col className={`text-shadow${center.theme} exercise-name`}>
              {props.exercise.name.slice(0, 25) +
                (props.exercise.name.length > 25 ? '...' : '')}
            </Col>
          </Row>
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{ height: '100%', display: 'flex', alignItems: 'center' }}
          >
            <Col
              span={4}
              style={{ display: 'flex', flexDirection: 'column' }}
              className={`text-shadow${center.theme} column-time`}
            >
              <p>
                <span>{capitalize(t('set'))}</span>
                <br />
                <span className={'sets-text'}>
                  <b>
                    {settings.sets.indexOf(currentSet) + 1}
                    {' / '}
                    {settings.sets.length}
                  </b>
                </span>
              </p>
              <p>
                <span>{capitalize(t('time'))}</span>
                <br />
                <span className={'column-chrono'}>
                  <b>
                    {(!countDown || dontStopTimer) && (
                      <ExerciseChrono
                        handleExerciseTime={handleExerciseTime}
                        startExercise={startExercise}
                      />
                    )}
                  </b>
                </span>
              </p>
            </Col>
            <Col
              span={16}
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                textAlign: 'center'
              }}
            >
              <div
                style={{
                  width: '100%',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <div
                  className='progress'
                  style={{
                    position: 'relative',
                    float: 'center',
                    textAlign: 'center',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <div className='barOverflow bar-overflow'>
                    <div
                      className='bar'
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '200%',
                        borderRadius: '50%',
                        boxSizing: 'border-box',
                        border: `80px solid rgba(${210 - progressBar * 2}, ${
                          210 - progressBar * 2
                        }, ${210 - progressBar * 2}, 1)`
                      }}
                    ></div>
                    <div
                      className='bar'
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '200%',
                        borderRadius: '50%',
                        boxSizing: 'border-box',
                        border: '80px solid transparent',
                        borderBottomColor: `rgba(${progressSqueeze}, ${
                          Math.trunc(progressBar) / 100 + 0.08
                        })`,
                        borderRightColor: `rgba(${progressSqueeze}, ${
                          Math.trunc(progressBar) / 100 + 0.08
                        })`,
                        transform: 'rotate(' + (45 + percent * 1.8) + 'deg)',
                        transition: `transform ${transition}s linear`
                      }}
                    ></div>
                    <div className={'radial-separators'}>
                      <RadialSeparators
                        count={currentSet.time * 2}
                        style={{
                          background: clockSqueeze,
                          width: '4px',
                          height: `${4}%`
                        }}
                      />
                      {/* <RadialSeparators */}
                      {/*  count={settings.concentric * 8} */}
                      {/*  style={{ */}
                      {/*    background: clockSqueeze, */}
                      {/*    width: '1px', */}
                      {/*    height: `${2}%` */}
                      {/*  }} */}
                      {/* /> */}
                    </div>
                  </div>
                  <div className={'main-chrono'}>
                    {!countDown && !setsInterval && (
                      <RepChrono
                        ref={repChronoRef}
                        isSetsType={true}
                        beep1={beep1}
                        beep2={beep2}
                        beep3={beep3}
                        concentric={parseInt(currentSet.time)}
                        eccentric={parseInt(currentSet.time)}
                        squeeze={0}
                        handleProgress={handleProgress}
                        handleReps={handleReps}
                        startExercise={startExercise}
                      />
                    )}
                    <div
                      className={`squeeze-color${center.theme} squeeze-text`}
                    >
                      {setsInterval && (
                        <>
                          <IntervalChrono
                            minutes={settings.interval}
                            onFiveSecondsLeft={() => {
                              setMessageModalVisible(true);
                              setCountDown(true);
                              setSetsInterval(false);
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col span={4} className={`text-shadow${center.theme} column-reps`}>
              <p>
                <span>{capitalize(t('reps'))}</span>
                <br />
                <span className={'reps-text'}>
                  <b>
                    {reps}
                    {' / '}
                    {currentSet.reps}
                  </b>
                </span>
              </p>
            </Col>
          </Row>
          <Row justify={'space-between'}>
            <Col className={`text-shadow${center.theme} column-exercise-info`}>
              <Row>
                {weight} {props.exercise.weightMeasure}
              </Row>
            </Col>
            <Col className={'column-exercise-img'}>
              <img
                className={'column-exercise-img-img'}
                src={center.logo}
                alt={'logo'}
              />
            </Col>
            <Col className={`text-shadow${center.theme} column-exercise-last`}>
              <Row>{t('lastSession').toUpperCase()}</Row>
              {lastExerciseSession ? (
                <>
                  <Row justify={'center'}>
                    {moment(lastExerciseSession.timestamp.createdAt).format(
                      'L'
                    )}
                  </Row>
                  <Row justify={'center'}>
                    <div>
                      {lastExerciseSession.sets.length}{' '}
                      {t('sets').toUpperCase()}
                    </div>
                  </Row>
                  <Row justify={'center'}>
                    {lastExerciseSession.weight} {props.exercise.weightMeasure}
                  </Row>
                  <Row justify={'center'} style={{ color: 'red' }}>
                    {lastExerciseSession.AP && <> AP </>}
                    {lastExerciseSession.NF && <> NF </>}
                    {lastExerciseSession.NS && <> NS </>}
                    {lastExerciseSession.MT && <> MT </>}
                  </Row>
                </>
              ) : (
                capitalize(t('noLastSession'))
              )}
            </Col>
          </Row>
          {/* </div> */}
        </div>
        {messageModalVisible && (
          <FullScreenMessageModal
            messageModalVisible={messageModalVisible}
            setMessageModalVisible={setMessageModalVisible}
            message={messageModal}
          />
        )}
      </div>
    </>
  );
};

export default ExerciseSets;
